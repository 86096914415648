// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  // const { user } = useAuth();
  const userData = JSON.parse(localStorage.getItem('userData'));

  return (
    <Avatar
      src={userData?.avatar}
      alt={userData?.firstName}
      color={userData?.avatar ? 'default' : createAvatar(userData?.firstName).color}
      {...other}
    >
      {createAvatar(userData?.firstName).name}
    </Avatar>
  );
}
