import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import {
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Autocomplete,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Close } from "@mui/icons-material";
import { createHowItWorkMedia } from "../../../redux/slices/howItWorkMedia";
import { useDispatch, useSelector } from "../../../redux/store";
import { getCountries } from "src/redux/slices/country";
import Select from "@mui/material/Select";
import { MuiChipsInput } from "mui-chips-input";
import ImageUploading from "react-images-uploading";
import CkEditor from "./CkEditor";
import VideoInput from "./VideoInput";

const toastOptions = {
  position: "top-right",
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
  theme: "dark",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        borderBottom: "1px solid #e5e8ec",
        width: 600,
        backgroundColor: "#f4f6f8",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CreateHowItWorkMediaModal({
  sites,
  open,
  handleClose,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [chips, setChips] = React.useState([]);
  const [images, setImages] = React.useState([]);
  const [description, setDescription] = React.useState([]);
  const { isLoading } = useSelector((state) => state.howItWorkMedia);
  const [source, setSource] = React.useState();

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  const { countries } = useSelector((state) => state.country);

  const handleSubmit = () => {
    const howItWorkMediaData = { ...data };
    dispatch(createHowItWorkMedia(howItWorkMediaData, handleClose));
  };

  const handleChangeHowItWorkMediaData = (event) => {
    const howItWorkMediaData = { ...data };
    howItWorkMediaData[event.target.name] = event.target.value;
    setData(howItWorkMediaData);
  };

  const inputRef = React.useRef();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setSource(url);
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  return (
    <div>
      <ToastContainer />

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {t("Add HowItWorkMedia")}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div
            style={{ display: "flex", flexDirection: "column" }}
            container
            spacing={3}
          >
            <Grid item lg={12} md={12}>
              <FormControl fullWidth>
                <InputLabel>Country *</InputLabel>
                <Select
                  onChange={handleChangeHowItWorkMediaData}
                  className="form-control"
                  native
                  name="country"
                  defaultValue=""
                  id="grouped-native-select"
                  label="Country "
                  required
                >
                  <option value=""></option>
                  {countries &&
                    countries?.map((val) => {
                      return <option value={val.country}>{val.country}</option>;
                    })}
                </Select>
              </FormControl>
            </Grid>
            <br />
            <Grid item lg={12} md={12}>
              <FormControl fullWidth>
                {/* <div className="VideoInput">
                  <input
                    ref={inputRef}
                    className="VideoInput_input"
                    type="file"
                    onChange={handleFileChange}
                    accept=".mov,.mp4"
                    hidden
                  />
                  {!source && (
                    <Button variant="contained" onClick={handleChoose}>
                      Select Video
                    </Button>
                  )}
                  {source && (
                    <video
                      className="VideoInput_video"
                      width="100%"
                      height={100}
                      controls
                      src={source}
                    />
                  )}
                  <div className="VideoInput_footer">
                    {source || "Nothing selectd"}
                  </div>
                </div> */}

                <TextField
                  sx={{
                    width: { xs: "100%" },
                  }}
                  name="video"
                  onChange={handleChangeHowItWorkMediaData}
                  id="outlined-basic"
                  label={t("Video Url")}
                  variant="outlined"
                  required
                />
              </FormControl>
            </Grid>
            <Stack
              sx={{
                mt: 3,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <LoadingButton
                onClick={() => handleClose()}
                variant="outlined"
                style={{ borderRadius: "25px", marginRight: "15px" }}
              >
                {t("cancel")}
              </LoadingButton>

              <LoadingButton
                style={{ borderRadius: "25px" }}
                // disabled={!data?.howItWorkMediaName}
                onClick={handleSubmit}
                variant="contained"
                loading={isLoading}
              >
                {t("createHowItWorkMedia")}
              </LoadingButton>
            </Stack>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
