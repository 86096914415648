/* eslint-disable prefer-const */
import * as React from 'react';
import { useCallback } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import { Avatar, Checkbox, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addMembers, clearError, getUsers, getGroups, getUserGroups } from '../../../redux/slices/people';
import { useDispatch, useSelector } from '../../../redux/store';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const tobel = { inputProps: { 'aria-label': 'Checkbox demo' } };
const toastOptions = {
  position: 'top-right',
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
  theme: 'dark',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #FFFFFF',
  boxShadow: 24,
  borderRadius: '20px',
};

export default function AddIntoGroup({ open, handleClose, userId }) {
  const dispatch = useDispatch();
  const [groupIds, setGroupIds] = React.useState([]);
  const { createError, isLoading } = useSelector((state) => state.people);
  const { groups } = useSelector((state) => state.people);
  const { userGroups } = useSelector((state) => state.people);
  React.useEffect(() => {
    dispatch(getUsers());
    dispatch(getGroups());
    dispatch(getUserGroups(userId));
  }, [userId]);
  React.useEffect(() => {
    if (createError) {
      toast.error(createError.message, toastOptions);
      dispatch(clearError);
    }
  }, [createError]);
  React.useEffect(() => {
    if (userGroups?.length > 0) {
      const userGroupsIds = userGroups.map((el) => el.id);
      setGroupIds(userGroupsIds);
    }
  }, [userGroups]);

  const handleSubmit = () => {
    const groupData = [...groupIds];
    dispatch(addMembers(groupData, handleClose, userId));
  };
  const handleChange = (id) => () => {
    const arrIds = [...groupIds];
    if (arrIds.includes(id)) {
      const currentIndex = arrIds.findIndex((el) => el === id);
      arrIds.splice(currentIndex, 1);
    } else {
      arrIds.push(id);
    }
    setGroupIds(arrIds);
  };
  return (
    <div>
      <ToastContainer />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              backgroundColor: '#f4f6f8',
              borderBottom: '1px solid #e5e8ec',
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
              height: '40px',
              padding: '10px',
            }}
          >
            <h4>Add to user in group</h4>

            <Button onClick={handleClose} style={{ cursor: 'pointer', fontWieght: 'bold', background: 'transparent' }}>
              X
            </Button>
          </div>
          <Grid
            style={{
              padding: '30px',
              display: 'flex',
            }}
            container
            spacing={1}
          >
            {groups.map((row) => (
              <Grid item lg={6} md={6} key={row.id} style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
                <Checkbox
                  {...label}
                  checked={groupIds.includes(row.id)}
                  onClick={handleChange(row.id)}
                  // tabIndex={-1}
                  //    value={row?.id}
                />
                <Avatar alt={row?.firstName} src={row?.avatar} sx={{ mr: 2 }} />
                <Typography variant="subtitle2" noWrap>
                  {`${row.name}`}
                </Typography>
              </Grid>
            ))}
            <Grid item lg={12} md={12}>
              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton
                  // disabled={!data?.name || !data?.description}
                  onClick={handleSubmit}
                  variant="contained"
                  loading={isLoading}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
