import * as React from 'react';
import { useCallback } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LoadingButton, Autocomplete } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/CreateUserModal.css';
// Redux
import { createUser, clearError } from '../../../redux/slices/people';
import { useDispatch, useSelector } from '../../../redux/store';

const toastOptions = {
  position: 'top-right',
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
  theme: 'dark',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #FFFFFF',
  boxShadow: 24,
  borderRadius: '20px',
};

// eslint-disable-next-line react/prop-types
export default function CreateUserModal({ roles, open, handleClose }) {
  const { t } = useTranslation();

  // console.log(`${roles}===============roles`)
  const dispatch = useDispatch();
  const [data, setData] = React.useState();
  const [roleId, setRoleId] = React.useState();

  const { createError, isLoading } = useSelector((state) => state.people);

  React.useEffect(() => {
    if (createError) {
      toast.error(createError.message, toastOptions);
      dispatch(clearError);
    }
  }, [createError]);

  const handleSubmit = () => {
    const userData = { ...data };
    userData.roleId = roleId;
    dispatch(createUser(userData, handleClose));
  };

  const handleChangeUserData = (event) => {
    const userData = { ...data };
    userData[event.target.name] = event.target.value;
    setData(userData);
  };

  return (
    <div>
      <ToastContainer />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              background: 'lightgray',
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
              height: '40px',
              padding: '10px',
            }}
          >
            <h4>{t('createUser')}</h4>

            <span style={{ cursor: 'pointer', fontWieght: 'bold' }}>X</span>
          </div>
          <Grid
            style={{
              padding: '30px',
            }}
            container
            spacing={1}
          >
            <Grid item lg={6} md={6}>
              <TextField
                name="firstName"
                onChange={handleChangeUserData}
                id="outlined-basic"
                label={t('firstName')}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <TextField
                name="lastName"
                onChange={handleChangeUserData}
                id="outlined-basic"
                label={t('lastName')}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item lg={12} md={12}>
              <TextField
                style={{
                  width: 490,
                }}
                name="email"
                onChange={handleChangeUserData}
                id="outlined-basic"
                label={t('email')}
                variant="outlined"
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <TextField
                style={{
                  outline: '0',
                }}
                name="startDateTime"
                onChange={handleChangeUserData}
                id="outlined-basic"
                label={t('startDate')}
                variant="outlined"
                type="datetime-local"
              />
            </Grid>

            <Grid item lg={6} md={6}>
              <TextField
                name="endDateTime"
                onChange={handleChangeUserData}
                id="outlined-basic"
                label={t('endDate')}
                variant="outlined"
                type="datetime-local"
              />
            </Grid>
            <Grid item lg={12} md={12}>
              {roles.length > 0 && (
                <Autocomplete
                  style={{
                    width: 490,
                  }}
                  className="mb-4 w-full"
                  options={roles}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => setRoleId(value.id)}
                  renderInput={(params) => <TextField {...params} label={t('role')} fullWidth />}
                />
              )}
            </Grid>
            <Stack sx={{ mt: 3, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>
              <LoadingButton
                onClick={() => handleClose()}
                variant="outlined"
                style={{ borderRadius: '25px', marginRight: '15px' }}
              >
                {t('cancel')}
              </LoadingButton>

              <LoadingButton
                style={{ borderRadius: '25px' }}
                disabled={!data?.name || !data?.description}
                onClick={handleSubmit}
                variant="contained"
                loading={isLoading}
              >
                {t('createUser')}
              </LoadingButton>
            </Stack>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
