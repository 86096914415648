import * as React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import { Checkbox, TableRow, TableCell, Box } from "@mui/material";
// components
import HeroMediaDetailModal from "../HeroMediaDetailModal";

// ----------------------------------------------------------------------

HeroMediaTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function HeroMediaTableRow({ row, selected, onSelectRow }) {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openTagsModal, setOpenTagsModal] = useState(false);
  const [openImagesModal, setOpenImagesModal] = useState(false);
  const [rowData, setRowData] = useState(false);

  console.log(row);

  const handleOpenDetailModal = (successMessage) => {
    setRowData(row);
    setOpenCreateModal(!openCreateModal);
  };
  return (
    <>
      <TableRow hover selected={selected} style={{ cursor: "pointer" }}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell
          align="left"
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
            textDecoration: "underline",
            color: "blue",
          }}
          onClick={() => handleOpenDetailModal()}
        >
          <img
            disabledEffect
            alt={"test"}
            src={row?.media}
            width={50}
            height={50}
            style={{ borderRadius: 1.5, marginRight: 15 }}
          />
          <Box sx={{ width: 150 }}>{row?.country}</Box>
        </TableCell>

        <TableCell align="left" sx={{ textTransform: "capitalize" }}>
          {row?.type}
        </TableCell>
      </TableRow>
      <HeroMediaDetailModal
        rowData={rowData}
        open={openCreateModal}
        handleClose={handleOpenDetailModal}
      />
    </>
  );
}
