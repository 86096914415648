import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// Sweet Alert
import Swal from 'sweetalert2';

// utils
import axios from '../../utils/axios';
// import axios from 'axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

// const headers = { Authorization: `Bearer ${localStorage.getItem('accessToken')}` };
let headers;
// eslint-disable-next-line no-unused-expressions
async () => {
  headers = await {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
};
const initialState = {
  isLoading: false,
  createError: null,
  getError: null,
  updateError: null,
  deleteError: null,
  heroMediaList: [],
  userDetails: null,
  groups: [],
  groupDetails: null,
  userGroups: [],
  groupUsers: [],
};

const slice = createSlice({
  name: 'heroMedia',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS CREATE ERROR
    hasCreateError(state, action) {
      state.isLoading = false;
      state.createError = action.payload;
    },

    // HAS UPDATE ERROR
    hasUpdateError(state, action) {
      state.isLoading = false;
      state.updateError = action.payload;
    },

    // HAS UPDATE ERROR
    hasGetError(state, action) {
      state.isLoading = false;
      state.getError = action.payload;
    },

    // HAS UPDATE ERROR
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload;
    },

    // ERROR SHOWN
    errorShown(state, action) {
      state.createError = null;
      state.updateError = null;
      state.getError = null;
      state.deleteError = null;
    },

    // GET User Success
    getHeroMediaSuccess(state, action) {
      state.isLoading = false;
      state.heroMediaList = action.payload;
    },

    // GET User DETAILS
    getUserDetailsSuccess(state, action) {
      state.isLoading = false;
      state.userDetails = action.payload;
    },

    // GET Group Success
    getGroupSuccess(state, action) {
      state.isLoading = false;
      state.groups = action.payload;
    },

    // GET User Group Success
    getUserGroupSuccess(state, action) {
      state.isLoading = false;
      state.userGroups = action.payload;
    },
    // GET Group Users Success
    getGroupUserSuccess(state, action) {
      state.isLoading = false;
      state.groupUsers = action.payload;
    },

    // GET Group DETAILS
    getGroupDetailsSuccess(state, action) {
      state.isLoading = false;
      state.groupDetails = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// User Functions
export function getHeroMedia() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('auth/all-hero-section-media');
      dispatch(slice.actions.getHeroMediaSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function createHeroMedia(data, imageFile, handleClose) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const heroMediaData = { ...data };
      if (imageFile?.length > 0) {
        let imageUrl = [];
        const formData = new FormData();
        console.log(imageFile[0], 'imagefile');
        formData.append('image[]', JSON.stringify(imageFile));
        await axios
          .post(
            'https://images.uplandsinspire.com/api/multiple/image/upload',
            formData,

            { headers: { 'Content-Type': 'multipart/form-data' } }
          )
          .then((res) => {
            if (res.data.status) {
              imageUrl = res?.data?.images[0]?.image;
            }
          })
          .catch((error) => {
            console.log(error);
          });

        heroMediaData.media = imageUrl;
      }
      const response = await axios.post('auth/create-update-herosection-media', heroMediaData, {
        headers,
      });
      handleClose(response.data.message);
      dispatch(getHeroMedia());
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}
export function importHeroMediaCSV(data, handleClose) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('auth/csv/upload', data, { headers });
      handleClose(response.data.message);
      dispatch(getHeroMedia());
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}

export function updateImage(data, handleSuccess) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('auth/update-heroMedia', data, {
        headers,
      });
      dispatch(slice.actions.stopLoading());
      handleSuccess(response.data.message);
      dispatch(getHeroMedia());
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function updateHeroMedia(data, handleSuccess) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('auth/update-heroMedia', data, {
        headers,
      });
      dispatch(slice.actions.stopLoading());
      handleSuccess(response.data.message);
      dispatch(getHeroMedia());
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function updateTag(data, handleSuccess) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('auth/update-heroMedia', data, {
        headers,
      });
      dispatch(slice.actions.stopLoading());
      handleSuccess(response.data.message);
      dispatch(getHeroMedia());
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function getHeroMediaDetails(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`zones/${id}`, { headers });
      dispatch(slice.actions.getHeroMediaDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function deleteHeroMedia(data, handleDeleteRes) {
  return async () => {
    try {
      const response = await axios.post('auth/delete-herosection-media', data, {
        headers,
      });
      dispatch(getHeroMedia());
      handleDeleteRes(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasGetError());
      handleDeleteRes(error.message);
    }
  };
}
