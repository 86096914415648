/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { useState } from 'react';

// Right Side Drawer
import * as React from 'react';
import Drawer from '@mui/material/Drawer';
// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, Checkbox, TableRow, Button, TableCell, Typography, MenuItem } from '@mui/material';
// components
import Fab from '@mui/material/Fab';
import { RowingOutlined } from '@mui/icons-material';
import { sum } from 'lodash';
import { useNavigate } from 'react-router';
import UserDrawer from '../UserDrawer';
import UserStatus from './UserStatus';
import PromoStatus from './PromoStatus';
import ResetPasswordModal from '../ResetPasswordModal';
// ----------------------------------------------------------------------

CountryTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function CountryTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [userDetail, setUserDetail] = React.useState({});
  const [isDetail, setDetail] = React.useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (anchor, open, row) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });

    setUserDetail(row);
    console.log('gdhfsn', userDetail);
  };
  const theme = useTheme();

  // console.log(JSON.stringify(row)+"===============row data")
  const {
    id,
    firstName,
    lastName,
    email,
    password,
    phone_no,
    address,
    date_of_birth,
    country,
    gender,
    status,
    id_Verification,
    image,
    is_email_verified,
    is_phone_verified,
    is_id_verified,
    id_image,
    is_super_admin,
    device_token,
    last_login_date,
    createdAt,
    updatedAt,
    initial_role,
    top_destination,
    travelerOffers,
    senderOffers,
    ratings,
  } = row;

  console.log('userrrrrrrrrrrr=====', row);
  const rating = ratings.map((v) => v.stars);
  // const avgRating = rating?.reduce((a, b) => a + b);
  // console.log(
  //   'userrrrrrrrrrrr=====1',
  //   avgRating
  //   // ratings?.reduce((acc, v) => acc + v.stars)
  // );

  function sumArray(array) {
    // const array = [1, 4, 0, 9, -3];
    let sum = 0;

    for (let i = 0; i < array.length; i += 1) {
      sum += array[i];
    }

    return sum;
  }
  sumArray(rating);
  console.log('userrrrrrrrrrrr=====sum', sumArray(rating));

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };
  const [userStatus, setUserStatus] = useState(false);
  const [promoStatus, setPromoStatus] = useState(false);

  const handleVerification = () => {
    navigate('/dashboard/user-verification', {
      state: {
        userId: id,
        userName: `${firstName} ${lastName}`,
        passportImage: id_image,
        device_token,
      },
    });
  };

  const handleUserStatus = () => {
    setUserStatus(!userStatus);
  };
  const handlePromoStatus = () => {
    setPromoStatus(!PromoStatus);
  };
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const handleOpenDetailModal = (successMessage) => {
    setOpenCreateModal(!openCreateModal);
  };
  return (
    <>
      <TableRow hover selected={selected} style={{ cursor: 'pointer' }}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell align="left" onClick={toggleDrawer('right', true)}>
          {id}
        </TableCell>
        <TableCell align="left">{firstName}</TableCell>
        <TableCell align="left">{lastName}</TableCell>
        <TableCell align="left">{email}</TableCell>
        <TableCell align="left">
          <Button variant="contained" to="" onClick={handleOpenDetailModal}>
            Reset
          </Button>
        </TableCell>
        <TableCell align="left">{initial_role}</TableCell>
        <TableCell align="left">{phone_no}</TableCell>
        <TableCell align="left">{address}</TableCell>
        <TableCell align="left">{country}</TableCell>
        <TableCell align="left">{top_destination?.country_one}</TableCell>
        <TableCell align="left">{top_destination?.country_two}</TableCell>
        <TableCell align="left">{top_destination?.country_three}</TableCell>
        <TableCell align="left">{date_of_birth}</TableCell>
        <TableCell align="left">{gender}</TableCell>
        <TableCell align="left">
          {/* <Button
            variant="contained"
            // component={RouterLink}
            to=""
            onClick={handleVerification}
            //   onClick={handleResetPassword}
            //   startIcon={<Iconify icon={'eva:plus-fill'} />}
          > */}

          {id_image != null ? (
            is_id_verified === 1 ? (
              <Button
                variant="contained"
                color="success"
                // component={RouterLink}
                to=""
                // disabled
                // onClick={handleVerification}
                //   onClick={handleResetPassword}
                //   startIcon={<Iconify icon={'eva:plus-fill'} />}
              >
                Verified
              </Button>
            ) : (
              // <ListItem onClick={handleVerification} button style={{ color: 'rgb(147, 149, 152)' }}>
              //   <ListItemText primary={"Check Verification"} />
              //   <img src={KeyIcon} alt="Unlock" />
              // </ListItem>
              <Button
                variant="contained"
                // component={RouterLink}
                to=""
                onClick={handleVerification}
                //   onClick={handleResetPassword}
                //   startIcon={<Iconify icon={'eva:plus-fill'} />}
              >
                Verify Id
              </Button>
            )
          ) : (
            // : <ListItemText style={{ color: 'red' }} primary={"User didn't uploaded Id yet."} />
            <Button
              variant="contained"
              // component={RouterLink}
              to=""
              disabled
              onClick={handleVerification}
              //   onClick={handleResetPassword}
              //   startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              User didn't uploaded Id yet
            </Button>
          )}

          {/*             
            Verify Id



          </Button> */}
        </TableCell>
        <TableCell align="left">
          <Button
            variant="contained"
            // component={RouterLink}
            to=""
            onClick={handleUserStatus}
            // color={status == 1 ? 'success' : null}
            //   onClick={handleResetPassword}
            //   startIcon={<Iconify icon={'eva:plus-fill'} />}
          >
            {status === 1 ? 'Enabled' : 'Disabled'}
          </Button>
        </TableCell>
        <TableCell align="left">{createdAt?.split('T')[0]}</TableCell>
        <TableCell align="left">{last_login_date?.split('T')[0]}</TableCell>
        <TableCell align="left">To be implemented in next stages</TableCell>
        <TableCell align="left">To be implemented in next stages</TableCell>
        <TableCell align="left">To be implemented in next stages</TableCell>
        <TableCell align="left">To be implemented in next stages</TableCell>
        <TableCell align="left">To be implemented in next stages</TableCell>
        <TableCell align="left">To be implemented in next stages</TableCell>
        <TableCell align="left">{senderOffers?.length}</TableCell>
        <TableCell align="left">To be implemented in next stages</TableCell>
        <TableCell align="left">To be implemented in next stages</TableCell>
        <TableCell align="left">{ratings?.length}</TableCell>

        <TableCell align="left">
          {ratings?.length > 0 ? Math.round((sumArray(rating) / ratings?.length) * 10) / 10 : 'No Ratings'}
        </TableCell>
        <TableCell align="left">To be implemented in next stages</TableCell>
        <TableCell align="left">To be implemented in next stages</TableCell>

        <UserStatus open={userStatus} userId={id} userStatus={status} handleClose={handleUserStatus} />
        <PromoStatus open={promoStatus} handleClose={handlePromoStatus} />

        {/* <TableCell align="center">
        <Iconify
          icon={isVerified ? 'eva:checkmark-circle-fill' : 'eva:clock-outline'}
          sx={{
            width: 20,
            height: 20,
            color: 'success.main',
            ...(!isVerified && { color: 'warning.main' }),
          }}
        />
      </TableCell> */}

        {/* <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(status === 'banned' && 'error') || 'success'}
          sx={{ textTransform: 'capitalize' }}
        >
          {status}
        </Label>
      </TableCell> */}
      </TableRow>
      <Drawer
        style={{ width: '324px', overflowX: 'hidden' }}
        anchor="right"
        open={state.right}
        onClose={toggleDrawer('right', false)}
      >
        {/* {list("right")} */}
        <UserDrawer position="right" userDetail={row} />
      </Drawer>
      <ResetPasswordModal id={row?.id} open={openCreateModal} handleClose={handleOpenDetailModal} />
    </>
  );
}
