import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// Sweet Alert
import Swal from 'sweetalert2';

// utils
import axios from '../../utils/axios';
// import axios from 'axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

// const headers = { Authorization: `Bearer ${localStorage.getItem('accessToken')}` };
let headers;
// eslint-disable-next-line no-unused-expressions
async () => {
  headers = await { Authorization: `Bearer ${localStorage.getItem('accessToken')}` };
};
const initialState = {
  isLoading: false,
  createError: null,
  getError: null,
  updateError: null,
  deleteError: null,
  supportList: [],
  userDetails: null,
  groups: [],
  groupDetails: null,
  userGroups: [],
  groupUsers: [],
};

const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS CREATE ERROR
    hasCreateError(state, action) {
      state.isLoading = false;
      state.createError = action.payload;
    },

    // HAS UPDATE ERROR
    hasUpdateError(state, action) {
      state.isLoading = false;
      state.updateError = action.payload;
    },

    // HAS UPDATE ERROR
    hasGetError(state, action) {
      state.isLoading = false;
      state.getError = action.payload;
    },

    // HAS UPDATE ERROR
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload;
    },

    // ERROR SHOWN
    errorShown(state, action) {
      state.createError = null;
      state.updateError = null;
      state.getError = null;
      state.deleteError = null;
    },

    // GET User Success
    getSupportSuccess(state, action) {
      state.isLoading = false;
      state.supportList = action.payload;
    },

    // GET User DETAILS
    getUserDetailsSuccess(state, action) {
      state.isLoading = false;
      state.userDetails = action.payload;
    },

    // GET Group Success
    getGroupSuccess(state, action) {
      state.isLoading = false;
      state.groups = action.payload;
    },

    // GET User Group Success
    getUserGroupSuccess(state, action) {
      state.isLoading = false;
      state.userGroups = action.payload;
    },
    // GET Group Users Success
    getGroupUserSuccess(state, action) {
      state.isLoading = false;
      state.groupUsers = action.payload;
    },

    // GET Group DETAILS
    getGroupDetailsSuccess(state, action) {
      state.isLoading = false;
      state.groupDetails = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// User Functions
export function getSupport() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('auth/all-support-messages');
      dispatch(slice.actions.getSupportSuccess(response.data.data));
      console.log(response.data, 'responseSupport');
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}
