import * as React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import { Checkbox, TableRow, TableCell, Button, Box } from "@mui/material";
// components
import HowItWorkMediaDetailModal from "../HowItWorkMediaDetailModal";
import TagsModal from "../TagsModal";

// ----------------------------------------------------------------------

HowItWorkMediaTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function HowItWorkMediaTableRow({ row, selected, onSelectRow }) {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openTagsModal, setOpenTagsModal] = useState(false);
  const [openImagesModal, setOpenImagesModal] = useState(false);
  const [rowData, setRowData] = useState(false);

  console.log(row);

  const handleOpenImagesModal = (successMessage) => {
    setRowData(row);
    setOpenImagesModal(!openImagesModal);
  };
  const handleOpenTagsModal = (successMessage) => {
    setRowData(row);
    setOpenTagsModal(!openTagsModal);
  };
  const handleOpenDetailModal = (successMessage) => {
    setRowData(row);
    setOpenCreateModal(!openCreateModal);
  };
  return (
    <>
      <TableRow hover selected={selected} style={{ cursor: "pointer" }}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell
          align="left"
          sx={{ textTransform: "capitalize" }}
          onClick={handleOpenDetailModal}
        >
          {row?.country}
        </TableCell>

        <TableCell align="left" sx={{ textTransform: "capitalize" }}>
          <video width="200" height="100" controls>
            <source src={row?.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </TableCell>
      </TableRow>
      <HowItWorkMediaDetailModal
        rowData={rowData}
        open={openCreateModal}
        handleClose={handleOpenDetailModal}
      />
    </>
  );
}
