import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// Sweet Alert
import Swal from 'sweetalert2';

// utils
import axios from '../../utils/axios';
// import axios from 'axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

let headers;
// eslint-disable-next-line no-unused-expressions
async () => {
  headers = await { Authorization: `Bearer ${localStorage.getItem('accessToken')}` };
};
// const headers = { Authorization: `Bearer ${localStorage.getItem('accessToken')}` };
// console.log(localStorage.getItem('accessToken'), 'abcddddd====');

const initialState = {
  isLoading: false,
  createError: null,
  getError: null,
  updateError: null,
  deleteError: null,
  allSenderOffers: [],
  allTravelerOffers: [],
  travelerOfferDetail: null,
  idVerification: null,
  senderOfferDetail: null,
  groups: [],
  groupDetails: null,
  userGroups: [],
  groupUsers: [],
  pricingDetails: null,
};

const slice = createSlice({
  name: 'people',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS CREATE ERROR
    hasCreateError(state, action) {
      state.isLoading = false;
      state.createError = action.payload;
    },

    // HAS UPDATE ERROR
    hasUpdateError(state, action) {
      state.isLoading = false;
      state.updateError = action.payload;
    },

    // HAS UPDATE ERROR
    hasGetError(state, action) {
      state.isLoading = false;
      state.getError = action.payload;
    },

    // HAS UPDATE ERROR
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload;
    },

    // ERROR SHOWN
    errorShown(state, action) {
      state.createError = null;
      state.updateError = null;
      state.getError = null;
      state.deleteError = null;
    },

    // GET Offer Success
    getAllSenderOffersSuccess(state, action) {
      state.isLoading = false;
      state.allSenderOffers = action.payload;
    },

    // GET Offer DETAILS
    getSenderOfferDetailSuccess(state, action) {
      state.isLoading = false;
      state.senderOfferDetail = action.payload;
    },

    getAllTravelerOffersSuccess(state, action) {
      state.isLoading = false;
      state.allTravelerOffers = action.payload;
    },

    // GET User DETAILS
    getTravelerOfferDetailSuccess(state, action) {
      state.isLoading = false;
      state.travelerOfferDetail = action.payload;
    },

    // GET Group Success
    getGroupSuccess(state, action) {
      state.isLoading = false;
      state.groups = action.payload;
    },

    // GET User Group Success
    getUserGroupSuccess(state, action) {
      state.isLoading = false;
      state.userGroups = action.payload;
    },
    // GET Group Users Success
    getGroupUserSuccess(state, action) {
      state.isLoading = false;
      state.groupUsers = action.payload;
    },

    // GET Group DETAILS
    getGroupDetailsSuccess(state, action) {
      state.isLoading = false;
      state.groupDetails = action.payload;
    },
    // GET Group DETAILS
    getVerificationSuccess(state, action) {
      state.isLoading = false;
      state.idVerification = action.payload;
    },
    getPricingSuccess(state, action) {
      state.isLoading = false;
      state.pricingDetails = action.payload;
    },
    getUpdatePricingSuccess(state, action) {
      state.isLoading = false;
      state.pricingDetails = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Offer Functions
export function getAllSenderOffers() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('auth/all-sender-offers');
      // console.log(JSON.stringify(response?.data?.data))
      dispatch(slice.actions.getAllSenderOffersSuccess(response.data.senderOffers));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function getAllTravelerOffers() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('auth/all-traveler-offers');
      // console.log(JSON.stringify(response?.data?.data))
      dispatch(slice.actions.getAllTravelerOffersSuccess(response.data.travlerOffers));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function getTravelerOfferDetail(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('auth/traveler-offer-details', data, { headers });
      dispatch(slice.actions.getTravelerOfferDetailSuccess(response.data.travelerOffer));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function createUser(data, handleClose) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('users', data, { headers });
      handleClose(response.data.message);
      Swal.fire(
        'Successfully Created',
        `Your Accocunt (Email: ${response.data.data.email} , Password: ${response.data.data.password}) Please save your Credentials`,
        'success'
      );
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}

export function updateUser(data, handleSuccess) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`users?id=${data.id}`, data, { headers });
      console.log(`${response.data} ==========update response`);
      dispatch(slice.actions.stopLoading());
      handleSuccess(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function updateOfferStatus(data, handleSuccess) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`auth/update-offer-status`, data, { headers });
      console.log(`${response.data} ==========update response`);
      dispatch(slice.actions.stopLoading());
      dispatch(getAllSenderOffers());
      handleSuccess(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function verifyUserIdImage(data, handleSuccess) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`auth/approve-id-verfication`, data, { headers });
      // console.log(`${response.data} ==========update response`);
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.getVerificationSuccess(response.data));
      handleSuccess(response.data);
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function sendNotification(data, handleSuccess) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('https://fcm.googleapis.com/fcm/send', data, {
        headers: {
          Authorization: `key=AAAA_bZ5LEY:APA91bEvhraf1nDzIrS4AoJaiRfB2ZZTP7HH_6xxppVc4br6dlxKjS0r09gaiyynGr4wGZ03d1xrwAhrsx4MIMry_thzh5YMRjuoJzDF9W-o_pf00rmVxN0RST-_cgiDXtd8DR0RDMO-`,
        },
      });
      // console.log(`${response.data} ==========update response`);
      dispatch(slice.actions.stopLoading());
      handleSuccess(response.data);
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function getSenderOfferDetail(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('auth/sender-offer-details', data, { headers });
      dispatch(slice.actions.getSenderOfferDetailSuccess(response.data.senderOffer));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

// Group Functions

export function getGroups(userId = null) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('groups/get-all-groups-with-members');
      dispatch(slice.actions.getGroupSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function getUserGroups(userId = null) {
  console.log(`${userId} in slice`);
  let route = 'groups/get-all-groups-with-members';
  if (userId != null) {
    route = 'groups/get-all-groups-belong-to-user';
  }
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`groups/get-all-groups-belong-to-user?id=${userId}`);
      dispatch(slice.actions.getUserGroupSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}
export function getGroupUsers(groupId = null) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`groups/get-all-users-belong-to-group?id=${groupId}`);
      dispatch(slice.actions.getGroupUserSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function createGroup(data, handleClose) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('groups', data, { headers });
      handleClose(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}

export function updateGroup(data, handleSuccess) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`groups?id=${data.id}`, data, { headers });
      dispatch(slice.actions.stopLoading());
      handleSuccess(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function getGroupDetails(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`groups/${id}`, { headers });
      dispatch(slice.actions.getGroupDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function clearError() {
  dispatch(slice.actions.errorShown());
}

// Switch Member in Group
export function switchMembersInGroup(data, handleClose, groupId) {
  const payload = {
    userIds: data,
  };
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`groups/add-members-to-group?id=${groupId}`, payload, { headers });
      dispatch(getGroups());
      // handleClose(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}

// Add Member to Group
export function addMembers(data, handleClose, userId) {
  const payload = {
    userId,
    groupIds: data,
  };
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`groups/add-user-to-multiple-groups`, payload, { headers });
      dispatch(getAllSenderOffers());
      handleClose(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };

  //
}

// pricing details get
export function getPricingDetails() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('auth/get-pricings');
      dispatch(slice.actions.getPricingSuccess(response.data.pricings));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}
// updata pricing
export function addUpdatePricing(data, handleSuccess) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`auth/add-update-pricings`, data, { headers });
      // console.log(`${response.data} ==========update response`);
      dispatch(slice.actions.stopLoading());
      dispatch(getPricingDetails());
      handleSuccess(response.data);
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function deleteTravellerOffer(data, handleDeleteRes) {
  return async () => {
    try {
      const response = await axios.post('auth/delete-traveler-offer', data, { headers });
      dispatch(getAllTravelerOffers());
      handleDeleteRes(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasGetError());
      handleDeleteRes(error.message);
    }
  };
}

export function deleteSenderOffer(data, handleDeleteRes) {
  return async () => {
    try {
      const response = await axios.post('auth/delete-sender-offer', data, { headers });
      dispatch(getAllSenderOffers());
      handleDeleteRes(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasGetError());
      handleDeleteRes(error.message);
    }
  };
}
