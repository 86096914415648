/* eslint-disable import/no-unresolved */
import { paramCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from 'react-csv';
// @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { getCountries } from 'src/redux/slices/country';

import { deleteHowItWorkMedia, getHowItWorkMedia } from '../../redux/slices/howItWorkMedia';
import { useDispatch, useSelector } from '../../redux/store';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';

// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../components/table';
// sections
import { HowItWorkMediaTableToolbar, HowItWorkMediaTableRow } from '../../sections/@dashboard/howItWorksMedia/list';
import CreateHowItWorkMediaModal from '../../sections/@dashboard/howItWorksMedia/CreateHowItWorkMediaModal';
// import ImportHowItWorkMediaCSV from "src/sections/@dashboard/howItWorksMedia/ImportHowItWorkMediaCSV";

// ----------------------------------------------------------------------
const toastOptions = {
  position: 'top-right',
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
  theme: 'dark',
};

export default function HowItWorksMediaList() {
  const { t } = useTranslation();
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();
  const TABLE_HEAD = [
    { id: 'id', label: t('select'), align: 'left' },
    { id: 'country', label: t('country'), align: 'left' },
    { id: 'video', label: t('video'), align: 'left' },
  ];

  const { themeStretch } = useSettings();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);

  const [filterName, setFilterName] = useState('');
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openImportCSVModal, setOpenImportCSVModal] = useState(false);

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleEditRow = (id) => {
    navigate(PATH_DASHBOARD.user.edit(paramCase(id)));
  };

  const dataFiltered = applySortFilter({
    tableData,
    filterName,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound = !dataFiltered.length && !!filterName;

  const { howItWorkMediaList } = useSelector((state) => state.howItWorkMedia);

  useEffect(() => {
    dispatch(getHowItWorkMedia());
  }, [dispatch]);
  useEffect(() => {
    setTableData(howItWorkMediaList);
  }, [howItWorkMediaList]);

  // download
  const [headerData, setHeaderData] = useState([]);
  const [tData, setTData] = useState([]);
  useEffect(() => {
    const headers = TABLE_HEAD?.map((header) => ({
      label: header.label,
      key: header.id,
    }));

    setHeaderData(headers);
    if (tableData) {
      const data = tableData?.map((row) => ({
        id: row.id,
        title: row.title,
        url_slug: row?.url_slug,
        short_description: row?.short_description,
        long_description: row?.long_description,
        tags: JSON.stringify(row?.howItWorkMediaTags),
        images: JSON.stringify(row?.howItWorkMediaImages),
        // country: row.country.country,
      }));

      setTData(data);
    }
  }, [tableData]);

  const handleOpenCreateNewZoneModal = (successMessage) => {
    if (successMessage) {
      toast.success(successMessage, toastOptions);
      dispatch(getHowItWorkMedia());
    }
    setOpenCreateModal(!openCreateModal);
  };
  const handleOpenImportCSVModal = (successMessage) => {
    setOpenImportCSVModal(!openImportCSVModal);
  };

  const handleDeleteRes = (message) => {
    Swal.fire('Successfully Deleted', message, 'info');
  };
  const handleDeleteRow = (id) => {
    const howItWorkMediaData = {
      howItWorkMediaIds: id,
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this zone!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'red',
      cancelButtonColor: 'grey',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteHowItWorkMedia(howItWorkMediaData, handleDeleteRes));
      }
    });
    setSelected([]);
  };

  return (
    <Page title={t('howItWorkMediaList')}>
      <ToastContainer />
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={t('HowItWorkMedia List')}
          links={[{ name: t('dashboard'), href: PATH_DASHBOARD.root }, { name: t('howItWorkMediaList') }]}
          action={
            <>
              <Button
                variant="contained"
                component={RouterLink}
                to=""
                onClick={handleOpenCreateNewZoneModal}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
              >
                {t('new HowItWorkMedia')}
              </Button>
              {/* <Button
                variant="contained"
                component={RouterLink}
                style={{ marginLeft: 10 }}
                to=""
                onClick={handleOpenImportCSVModal}
                startIcon={<Iconify icon={"eva:plus-fill"} />}
              >
                {t("Import HowItWorkMedia CSV")}
              </Button> */}
              <Button
                variant="contained"
                // component={RouterLink}
                onClick={() => {}}
                // to
                style={{ marginLeft: 10 }}
              >
                <CSVLink data={tData} headers={headerData} filename={'howItWorkMedia.csv'} target="_blank">
                  {t('download HowItWorkMedia List')}
                </CSVLink>
              </Button>
            </>
          }
        />

        <Card>
          <HowItWorkMediaTableToolbar filterName={filterName} onFilterName={handleFilterName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRow(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                />

                <TableBody>
                  {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <HowItWorkMediaTableRow
                      key={row.id}
                      row={row}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                    />
                  ))}

                  <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
      <CreateHowItWorkMediaModal open={openCreateModal} handleClose={handleOpenCreateNewZoneModal} />
      {/* <ImportHowItWorkMediaCSV
        open={openImportCSVModal}
        handleClose={handleOpenImportCSVModal}
      /> */}
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  }

  return tableData;
}
