import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// Sweet Alert
import Swal from 'sweetalert2';

// utils
import axios from '../../utils/axios';
// import axios from 'axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

// const headers = { Authorization: `Bearer ${localStorage.getItem('accessToken')}` };
let headers;
// eslint-disable-next-line no-unused-expressions
async () => {
  headers = await { Authorization: `Bearer ${localStorage.getItem('accessToken')}` };
};
const initialState = {
  isLoading: false,
  createError: null,
  getError: null,
  updateError: null,
  deleteError: null,
  payments: [],
  userDetails: null,
  groups: [],
  groupDetails: null,
  userGroups: [],
  groupUsers: [],
};

const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS CREATE ERROR
    hasCreateError(state, action) {
      state.isLoading = false;
      state.createError = action.payload;
    },

    // HAS UPDATE ERROR
    hasUpdateError(state, action) {
      state.isLoading = false;
      state.updateError = action.payload;
    },

    // HAS UPDATE ERROR
    hasGetError(state, action) {
      state.isLoading = false;
      state.getError = action.payload;
    },

    // HAS UPDATE ERROR
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload;
    },

    // ERROR SHOWN
    errorShown(state, action) {
      state.createError = null;
      state.updateError = null;
      state.getError = null;
      state.deleteError = null;
    },

    // GET User Success
    getPaymentSuccess(state, action) {
      state.isLoading = false;
      state.payments = action.payload;
    },

    // GET User DETAILS
    getUserDetailsSuccess(state, action) {
      state.isLoading = false;
      state.userDetails = action.payload;
    },

    // GET Group Success
    getGroupSuccess(state, action) {
      state.isLoading = false;
      state.groups = action.payload;
    },

    // GET User Group Success
    getUserGroupSuccess(state, action) {
      state.isLoading = false;
      state.userGroups = action.payload;
    },
    // GET Group Users Success
    getGroupUserSuccess(state, action) {
      state.isLoading = false;
      state.groupUsers = action.payload;
    },

    // GET Group DETAILS
    getGroupDetailsSuccess(state, action) {
      state.isLoading = false;
      state.groupDetails = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// User Functions
export function getPayment() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('auth/zone-payment-list');
      // console.log(JSON.stringify(response?.data?.data))
      dispatch(slice.actions.getPaymentSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function createPayment(data, handleClose) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('auth/create-zone-price', data, { headers });
      dispatch(getPayment());
      handleClose(response.data.message);
      Swal.fire(
        'Successfully Created',
        // `Your Accocunt (Email: ${response.data.data.email} , Password: ${response.data.data.password}) Please save your Credentials`,
        'success'
      );
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}

export function updatePayment(data, handleClose) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('auth/update-zone-price', data, { headers });
      dispatch(getPayment());
      handleClose(response.data.message);
      Swal.fire(
        'Successfully Updated...',
        // `Your Accocunt (Email: ${response.data.data.email} , Password: ${response.data.data.password}) Please save your Credentials`,
        'success'
      );
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}

export function deletePayment(data, handleDeleteRes) {
  return async () => {
    try {
      const response = await axios.post('auth/delete-zone-price', data, { headers });
      dispatch(getPayment());
      handleDeleteRes(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasGetError());
      handleDeleteRes(error.message);
    }
  };
}

export function updateUser(data, handleSuccess) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`payments?id=${data.id}`, data, { headers });
      console.log(`${response.data} ==========update response`);
      dispatch(slice.actions.stopLoading());
      handleSuccess(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function getUserDetails(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('auth/get-user', data, { headers });
      dispatch(slice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

// Group Functions

export function getGroups(userId = null) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('groups/get-all-groups-with-members');
      dispatch(slice.actions.getGroupSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function getUserGroups(userId = null) {
  console.log(`${userId} in slice`);
  let route = 'groups/get-all-groups-with-members';
  if (userId != null) {
    route = 'groups/get-all-groups-belong-to-user';
  }
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`groups/get-all-groups-belong-to-user?id=${userId}`);
      dispatch(slice.actions.getUserGroupSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}
export function getGroupUsers(groupId = null) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`groups/get-all-payments-belong-to-group?id=${groupId}`);
      dispatch(slice.actions.getGroupUserSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function createGroup(data, handleClose) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('groups', data, { headers });
      handleClose(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}

export function updateGroup(data, handleSuccess) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`groups?id=${data.id}`, data, { headers });
      dispatch(slice.actions.stopLoading());
      handleSuccess(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function getGroupDetails(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`groups/${id}`, { headers });
      dispatch(slice.actions.getGroupDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function clearError() {
  dispatch(slice.actions.errorShown());
}

// Switch Member in Group
export function switchMembersInGroup(data, handleClose, groupId) {
  const payload = {
    userIds: data,
  };
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`groups/add-members-to-group?id=${groupId}`, payload, { headers });
      dispatch(getGroups());
      // handleClose(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}

// Add Member to Group
export function addMembers(data, handleClose, userId) {
  const payload = {
    userId,
    groupIds: data,
  };
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`groups/add-user-to-multiple-groups`, payload, { headers });
      dispatch(getPayment());
      handleClose(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}
