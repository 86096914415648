import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const headers = { Authorization: `Bearer ${localStorage.getItem('accessToken')}` };

const initialState = {
  organization: null,
};

const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    // GET Organization
    getOrganizationSuccess(state, action) {
      state.organization = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getOrganization() {
  return async () => {
    try {
      const response = await axios.get('organizations');
      dispatch(slice.actions.getOrganizationSuccess(response.data.data));
    } catch (error) {
      console.log('Got the error while getting organization');
      console.log(error);
    }
  };
}
