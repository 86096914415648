import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import locationReducer from './slices/location';
import accessruleReducer from './slices/accessrule';
import peopleReducer from './slices/people';
import countryReducer from './slices/country';
import paymentReducer from './slices/payment';
import offerReducer from './slices/offers';
import roleReducer from './slices/roles';
import organizationReducer from './slices/organization';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import lockReducer from './slices/locks';
import logsReducer from './slices/logs';
import zoneReducer from './slices/zone';
import cityReducer from './slices/city';
import orderReducer from './slices/order';
import supportReducer from './slices/support';
import heroMediaReducer from './slices/heroMedia';
import howItWorkMediaReducer from './slices/howItWorkMedia';
import blogReducer from './slices/blog';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  people: peopleReducer,
  country: countryReducer,
  payment: paymentReducer,
  offers: offerReducer,
  roles: roleReducer,
  location: locationReducer,
  accessrule: accessruleReducer,
  organization: organizationReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  locks: lockReducer,
  logs: logsReducer,
  zone: zoneReducer,
  order: orderReducer,
  city: cityReducer,
  blog: blogReducer,
  heroMedia: heroMediaReducer,
  howItWorkMedia: howItWorkMediaReducer,
  support: supportReducer,
  product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
