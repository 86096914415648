import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
// import axios from 'axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const headers = { Authorization: `Bearer ${localStorage.getItem('accessToken')}` };

const initialState = {
  isLoading: false,
  createError: null,
  getError: null,
  updateError: null,
  deleteError: null,
  sites: [],
  siteDetails: null,
  zones: [],
  zoneDetails: null,
  areas: [],
  areaDetails: null,
  siteZones: [],
};

const slice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS CREATE ERROR
    hasCreateError(state, action) {
      state.isLoading = false;
      state.createError = action.payload;
    },

    // HAS UPDATE ERROR
    hasUpdateError(state, action) {
      state.isLoading = false;
      state.updateError = action.payload;
    },

    // HAS UPDATE ERROR
    hasGetError(state, action) {
      state.isLoading = false;
      state.getError = action.payload;
    },

    // HAS UPDATE ERROR
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload;
    },

    // ERROR SHOWN
    errorShown(state, action) {
      state.createError = null;
      state.updateError = null;
      state.getError = null;
      state.deleteError = null;
    },

    // GET SITES
    getSitesSuccess(state, action) {
      state.isLoading = false;
      state.sites = action.payload;
    },

    // GET SITE DETAILS
    getSiteDetailsSuccess(state, action) {
      state.isLoading = false;
      state.siteDetails = action.payload;
    },

    // GET ZONES
    getZonesSuccess(state, action) {
      state.isLoading = false;
      state.zones = action.payload;
    },

    // GET ZONE DETAILS
    getZoneDetailsSuccess(state, action) {
      state.isLoading = false;
      state.zoneDetails = action.payload;
    },

    // GET AREAS
    getAreasSuccess(state, action) {
      state.isLoading = false;
      state.areas = action.payload;
    },

    // GET AREA DETAILS
    getAreaDetailsSuccess(state, action) {
      state.isLoading = false;
      state.areaDetails = action.payload;
    },

    // GET SITE ZONES
    getSiteZonesSuccess(state, action) {
      state.isLoading = false;
      state.siteZones = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
// Site Functions

export function getSites() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('sites');
      dispatch(slice.actions.getSitesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function createSite(data, handleClose) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('sites', data, { headers });
      handleClose(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}

export function updateSite(data, handleSuccess) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`sites?id=${data.id}`, data, { headers });
      dispatch(slice.actions.stopLoading());
      handleSuccess(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function getSiteDetails(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`sites/${id}`, { headers });
      dispatch(slice.actions.getSiteDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function deleteSite(id, handleDeleteRes) {
  return async () => {
    try {
      const response = await axios.delete(`sites?id=${id}`, { headers });
      handleDeleteRes(response.data.message);
      dispatch(getSites());
    } catch (error) {
      dispatch(slice.actions.hasGetError());
      handleDeleteRes(error.message);
    }
  };
}

// ----------------------------------------------------------------------
// Zone Functions

export function getZones() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('zones');
      dispatch(slice.actions.getZonesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function createZone(data, handleClose) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('zones', data, { headers });
      handleClose(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}

export function updateZone(data, site, handleSuccess) {
  const data1 = {
    data,
    siteId: site,
  };
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`zones?id=${data.id}`, data1, { headers });
      dispatch(slice.actions.stopLoading());
      handleSuccess(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function getZoneDetails(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`zones/${id}`, { headers });
      dispatch(slice.actions.getZoneDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function deleteZone(id, handleDeleteRes) {
  return async () => {
    try {
      const response = await axios.delete(`zones?id=${id}`, { headers });
      handleDeleteRes(response.data.message);
      dispatch(getZones());
    } catch (error) {
      dispatch(slice.actions.hasGetError());
      handleDeleteRes(error.message);
    }
  };
}

// ----------------------------------------------------------------------
// Area Functions

export function getAreas() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('areas');
      dispatch(slice.actions.getAreasSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function createArea(data, handleClose) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('areas', data, { headers });
      handleClose(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}

export function updateArea(data, zoneId, handleSuccess) {
  const data1 = {
    data,
    zoneId,
  };
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`areas?id=${data.id}`, data1, { headers });
      dispatch(slice.actions.stopLoading());
      handleSuccess(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function getAreaDetails(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`areas/${id}`, { headers });
      dispatch(slice.actions.getAreaDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function deleteArea(id, handleDeleteRes) {
  return async () => {
    try {
      const response = await axios.delete(`areas?id=${id}`, { headers });
      handleDeleteRes(response.data.message);
      dispatch(getAreas());
    } catch (error) {
      dispatch(slice.actions.hasGetError());
      handleDeleteRes(error.message);
    }
  };
}

// ----------------------------------------------------------------------
// ZoneSite Functions

export function getSiteZones() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('zones/get-all-sites-and-zones');
      dispatch(slice.actions.getSiteZonesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function clearError() {
  dispatch(slice.actions.errorShown());
}
