import { useEffect } from 'react';
import * as React from 'react';
import { Box, Grid, Stack, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
// Icons
import EditIcon from './icons/edit.png';
import ExportIcon from './icons/export (1).png';
import KeyIcon from './icons/key.png';
import GroupsIcon from './icons/group.webp';
import DeleteIcon from './icons/delete.webp';
// Component
import Label from '../../../components/Label';
import AddIntoGroup from '../group/AddToGroupModal';

// slice
import { getUsers, getUserDetails, updateUser, clearError } from '../../../redux/slices/people';
import { useDispatch, useSelector } from '../../../redux/store';
// Right Side Drawer
// ----------------------------------------------------------------------

const toastOptions = {
  style: { width: '310px' },
  position: 'top-right',
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
  theme: 'dark',
};
const style = {
  marginLeft: '10px',
  width: '100%',
  maxWidth: 360,
  bgcolor: 'background.paper',
};
export default function UserDrawer(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [userData, setUserData] = React.useState({});
  const [selectedUserId, setUserId] = React.useState(null);
  // const { updateError, isLoading, userDetails } = useSelector((state) => state.people);

  // useEffect(() => {
  //   let data = {

  //   }
  //   dispatch(getUserDetails(props.userId));
  // }, []);

  useEffect(() => {

    console.log(JSON.stringify(props.userDetail) + "====user details")
    if (props.userDetail) {
      setUserData({ ...props.userDetail });
    }
  }, [props.userDetail]);

  console.log("userDataaaaa",userData)

  // useEffect(() => {
  //   if (updateError) {
  //     toast.error(updateError.message, toastOptions);
  //     dispatch(clearError);
  //   }
  // }, [updateError]);
  const [openAddGroupModal, setOpenAddGroupModal] = React.useState(false);
  const toggleDrawer = (props, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [props]: open });
  };

  const handleChangeUserData = (event) => {
    const data = { ...userData };
    data[event.target.name] = event.target.value;
    setUserData(data);
  };

  const handleSubmit = () => {
    dispatch(updateUser(userData, handleSuccess));
  };

  const handleClick = () => {
    setUserId(props.userId);
    setOpenAddGroupModal(!openAddGroupModal);
  };

  const handleSuccess = (message) => {
    toast.success(message, toastOptions);
    dispatch(getUsers());
  };

  const handleVerification = () => {
    navigate('/dashboard/user-verification', {
      state: { userId: userData?.id, userName: `${userData?.firstName} ${userData?.lastName}`, passportImage: userData?.id_image },
    });
  };

  return (
    <>
      <ToastContainer />
      <br />
      <br />
      <Box
        style={{ width: '324px', overflowX: 'hidden' }}
        sx={{ width: props.position === 'top' || props.position === 'bottom' ? 'auto' : 300 }}
        role="presentation"
        onClick={toggleDrawer(props.position, false)}
        onKeyDown={toggleDrawer(props.position, false)}
      >
        <Grid
          style={{ marginTop: '10px' }}
          sx={{
            display: 'grid',
          }}
          container
          spacing={3}
        >
          <Grid item xs={12}>
            <div
              style={{
                width: '200px',
                height: '200px',
                marginLeft: '50px',
                border: '1px solid blue',
                borderRadius: '50%',
                textAlign: 'center',
                background: 'lightgrey',
              }}
            >
              {userData?.avatar ? (
                <img
                  style={{ borderRadius: '50%' }}
                  src={userData?.avatar ? userData?.avatar : ''}
                  alt={userData?.firstName ? userData?.firstName : ''}
                  height={200}
                  width={200}
                />
              ) : (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <div style={{ marginTop: '60px', fontSize: '3em' }}>
                  {userData?.firstName ? userData?.firstName.substring(0, 1) : ''}{' '}
                  {userData?.lastName ? userData?.lastName.substring(0, 1) : ''}
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12} style={{ margin: '10px' }}>
            <TextField
              sx={{
                width: { xs: 280 },
              }}
              name="firstName"
              onChange={handleChangeUserData}
              value={userData?.firstName ? userData?.firstName : ''}
              id="outlined-basic"
              label={t('firstName')}
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} style={{ margin: '10px' }}>
            <TextField
              sx={{
                width: { xs: 280 },
              }}
              name="lastName"
              onChange={handleChangeUserData}
              value={userData?.lastName ? userData?.lastName : ''}
              id="outlined-basic"
              label={t('lastName')}
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} style={{ margin: '10px' }}>
            <TextField
              sx={{
                width: { xs: 280 },
              }}
              name="email"
              onChange={handleChangeUserData}
              value={userData?.email ? userData?.email : ''}
              id="outlined-basic"
              label={t('email')}
              variant="outlined"
            />
          </Grid>
          <Stack alignItems="center" sx={{ mt: 3 }}>
            <LoadingButton
              style={{ width: '200px', borderRadius: '20px' }}
              // disabled={!userData?.firstName || !userData?.email}
              onClick={handleSubmit}
              variant="contained"
            // loading={isLoading}
            >
              {t('save')}
            </LoadingButton>
          </Stack>
          <Grid item xs={12} style={{ margin: '10px' }}>
            <p style={{ marginTop: '10px', fontSize: '20px' }}>{t('setting')}</p>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <List sx={style} component="nav" aria-label="mailbox folders">
              {userData.id_image != null ?
              userData.is_id_verified == 1 ?
              <ListItem button style={{ color: 'rgb(147, 149, 152)' }}>
                <ListItemText style={{ color: 'green' }} primary={"Verified"} />

              </ListItem> :
              <ListItem onClick={handleVerification} button style={{ color: 'rgb(147, 149, 152)' }}>
                <ListItemText primary={"Check Verification"} />
                <img src={KeyIcon} alt="Unlock" />
              </ListItem>
              : <ListItemText style={{ color: 'red' }} primary={"User didn't uploaded Id yet."} />
              }
              <Divider />
              {/* <ListItem button divider style={{ color: 'rgb(147, 149, 152)' }}>
                <ListItemText primary={t('export')} />
                <img src={ExportIcon} alt="Export" />
              </ListItem>
              <Divider />
              <ListItem button style={{ color: 'rgb(147, 149, 152)' }}>
                <ListItemText primary={t('Unlocks')} />
                <img src={KeyIcon} alt="Unlock" />
              </ListItem>
              <Divider />
              <ListItem button onClick={() => handleClick()} style={{ color: 'rgb(147, 149, 152)' }}>
                <ListItemText primary={t('group')} />
                <img src={GroupsIcon} alt="Group" />
              </ListItem>
              <Divider /> */}
              {/* <ListItem button style={{ color: 'rgb(147, 149, 152)' }}>
                <ListItemText primary={t('delete')} />
                <img src={DeleteIcon} alt="Delete" />
              </ListItem> */}
            </List>
          </Grid>
        </Grid>
      </Box>

      <AddIntoGroup open={openAddGroupModal} handleClose={handleClick} userId={selectedUserId} />
    </>
  );
}
