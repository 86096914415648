import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
// import axios from 'axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const headers = { Authorization: `Bearer ${localStorage.getItem('accessToken')}` };

const initialState = {
  isLoading: false,
  createError: null,
  getError: null,
  updateError: null,
  deleteError: null,
  locks: [],
  lockDetails: null,
};

const slice = createSlice({
  name: 'locks',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS CREATE ERROR
    hasCreateError(state, action) {
      state.isLoading = false;
      state.createError = action.payload;
    },

    // HAS UPDATE ERROR
    hasUpdateError(state, action) {
      state.isLoading = false;
      state.updateError = action.payload;
    },

    // HAS UPDATE ERROR
    hasGetError(state, action) {
      state.isLoading = false;
      state.getError = action.payload;
    },

    // HAS UPDATE ERROR
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload;
    },

    // ERROR SHOWN
    errorShown(state, action) {
      state.createError = null;
      state.updateError = null;
      state.getError = null;
      state.deleteError = null;
    },

    // GET locks
    getLocksSuccess(state, action) {
      state.isLoading = false;
      state.locks = action.payload;
    },

    // GET Lock DETAILS
    getLockDetailsSuccess(state, action) {
      state.isLoading = false;
      state.siteDetails = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Site Functions
// ----------------------------------------------------------------------

export function getLocks() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('devices/get-all-locks');
      dispatch(slice.actions.getLocksSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function clearError() {
  dispatch(slice.actions.errorShown());
}
