import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { TextField, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CleaningServices, Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ImageUploading from 'react-images-uploading';
import { updateOfferStatus } from 'src/redux/slices/offers';
import { useDispatch } from 'react-redux';
import { updateOfferStatus1 } from 'src/redux/slices/order';
// import CkEditor from './CkEditor';
// import { allCategoryList, getCategoryList } from '../../../redux/slices/category';
// import { useDispatch, useSelector } from '../../../redux/store';
// import { enabledServices } from '../../../redux/slices/admin';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{ m: 0, p: 2, borderBottom: '1px solid #e5e8ec', width: 600, backgroundColor: '#f4f6f8' }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function EnableServicesModal({ open, handleClose, rowData }) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [data, setData] = React.useState();
  // const handleSubmit = () => {
  //   const siteData = { ...data };
  //   siteData.organizationId = organization.id;
  //   dispatch(createSite(siteData, handleClose));
  // };
  const [status, setStatus] = React.useState();
  const [description, setDescription] = React.useState();

  const [title, setTitle] = React.useState();
  const [price, setPrice] = React.useState();

  const [subcategoryId, setSubcategoryId] = React.useState();
  const [images, setImages] = React.useState([]);
  const maxNumber = 4;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  React.useEffect(() => {
    if (rowData?.id > 0) {
      setTitle(rowData?.title);
      setPrice(rowData?.price);
      setDescription(rowData?.description);
      setImages([{ data_url: rowData?.image_url }]);
    }
  }, [rowData]);

  // const handleSubmit = () => {
  //   let productData = {
  //     category_id: rowData?.id,
  //     title: title,
  //     description: description,
  //   };

  //   dispatch(updateService(productData, images, handleClose));
  // };
  const handleSubmit = () => {
    let data = {
      offerId: rowData?.id,
      offerType: 'travelerOffer',
      status: status,
    };

    dispatch(updateOfferStatus1(data, handleClose));
  };

  return (
    <div>
      <ToastContainer />
      <BootstrapDialog maxWidth={'sm'} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle maxWidth={'sm'} id="customized-dialog-title" onClose={handleClose}>
          Order Status
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {/* <Box sx={style}> */}
          <Grid container spacing={3}>
            <Grid
              style={{
                padding: '30px',
              }}
              container
              spacing={1}
            >
              <Grid item lg={4} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    onChange={(e) => setStatus(e.target.value)}
                    className="form-control"
                    native
                    defaultValue=""
                    id="grouped-native-select"
                    label="Status"
                    name="priceType"
                  >
                    <option value=""></option>
                    <option value="Complete and Paid">Complete and Paid</option>
                    {/* <option value="Missing">Missing</option> */}
                    <option value="Expired">Expired</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Refunded">Refunded</option>
                    <option value="First Mile Initiated">First Mile Initiated</option>
                    <option value="First Mile In Progress">First Mile In Progress</option>
                    {/* <option value="Main Transit In Progress">Main Transit In Progress</option> */}
                    <option value="Last Mile In Progress">Last Mile In Progress</option>
                    <option value="Accepted Awaiting payment">Accepted Awaiting payment</option>
                    <option value="Paid by Sender to Viahop">Paid by Sender to Viahop</option>
                    <option value="Delivery Confirmed">Delivery Confirmed</option>
                    <option value="Delivery in Process">Delivery in Process</option>
                    <option value="Delivery Complete by Traveller">Delivery Complete by Traveller</option>
                    <option value="Delivery Confirmed">Delivery Confirmed</option>

                    <option value="Delivered">Delivered</option>
                  </Select>
                </FormControl>
              </Grid>
              <Stack sx={{ mt: 5, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>
                <LoadingButton
                  onClick={() => handleClose()}
                  variant="outlined"
                  style={{ borderRadius: '25px', marginRight: '15px' }}
                >
                  Cancel
                </LoadingButton>

                <LoadingButton
                  style={{ borderRadius: '25px' }}
                  // disabled={status === '' || status === undefined}
                  onClick={handleSubmit}
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
          {/* </Box> */}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
