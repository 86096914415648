import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
// import axios from 'axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const headers = { Authorization: `Bearer ${localStorage.getItem('accessToken')}` };

const initialState = {
  isLoading: false,
  createError: null,
  getError: null,
  updateError: null,
  deleteError: null,
  accessRules: [],
  accessRuleDetails: null,
};

const slice = createSlice({
  name: 'accessrule',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS CREATE ERROR
    hasCreateError(state, action) {
      state.isLoading = false;
      state.createError = action.payload;
    },

    // HAS UPDATE ERROR
    hasUpdateError(state, action) {
      state.isLoading = false;
      state.updateError = action.payload;
    },

    // HAS GET ERROR
    hasGetError(state, action) {
      state.isLoading = false;
      state.getError = action.payload;
    },

    // HAS DELETE ERROR
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload;
    },

    // ERROR SHOWN
    errorShown(state, action) {
      state.createError = null;
      state.updateError = null;
      state.getError = null;
      state.deleteError = null;
    },

    // GET ACCESS RULES
    getAccessRulesSuccess(state, action) {
      state.isLoading = false;
      state.accessRules = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAccessRules(type, userId, groupId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`accessrules?type=${type}&userId=${userId}&groupId=${groupId}`);
      dispatch(slice.actions.getAccessRulesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function createAccessRule(data, handleClose) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('accessrules', data, { headers });
      handleClose(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}

export function clearError() {
  dispatch(slice.actions.errorShown());
}
