// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 0.5, height: 0.5 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  circle: getIcon('circle'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  // circle: getIcon("ic_circle"),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const configs = [
  // Nextxs
  // ----------------------------------------------------------------------
  {
    subheader: 'Viahop',
    items: [
      // {
      //   title: 'App Roles',
      //   path: PATH_DASHBOARD.user.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'Roles', path: PATH_DASHBOARD.roles.list },
      //     // { title: 'User Roles', path: PATH_DASHBOARD.user.list },
      //     { title: 'Role Permissions', path: PATH_DASHBOARD.rolePermissions.list },
      //   ],
      // },
      // {
      //   title: 'Logs',
      //   path: PATH_DASHBOARD.logs.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'Unlocks', path: PATH_DASHBOARD.logs.unlocks.list },
      //     { title: 'Presence', path: PATH_DASHBOARD.logs.presence.list },
      //   ],
      // },
      // {
      //   title: 'Locations',
      //   path: PATH_DASHBOARD.locations.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'Sites', path: PATH_DASHBOARD.locations.sites.list },
      //     { title: 'Zones', path: PATH_DASHBOARD.locations.zones.list },
      //     { title: 'Areas', path: PATH_DASHBOARD.locations.areas.list },
      //     // { title: 'Entries', path: PATH_DASHBOARD.user.list },
      //   ],
      // },
      {
        title: 'User Control Panel',
        path: PATH_DASHBOARD.people.root,
        icon: ICONS.circle,
        children: [
          { title: 'Users', path: PATH_DASHBOARD.people.users.list },
          // { title: 'Groups', path: PATH_DASHBOARD.people.groups.list },
        ],
      },
      {
        title: 'Order Control Panel',
        path: PATH_DASHBOARD.orders.root,
        icon: ICONS.circle,
        children: [
          { title: 'Sender Orders', path: PATH_DASHBOARD.orders.orders.list },
          {
            title: 'Traveller Orders',
            path: PATH_DASHBOARD.orders.traveller.list,
          },
          // { title: 'Groups', path: PATH_DASHBOARD.people.groups.list },
        ],
      },
      {
        title: 'Country Control Panel',
        path: PATH_DASHBOARD.countries.root,
        icon: ICONS.circle,
        children: [
          { title: 'Countries', path: PATH_DASHBOARD.countries.countries.list },
          // { title: 'Groups', path: PATH_DASHBOARD.people.groups.list },
        ],
      },
      {
        title: 'Zone Control Panel',
        path: PATH_DASHBOARD.zone.root,
        icon: ICONS.circle,
        children: [
          { title: 'Zones', path: PATH_DASHBOARD.zone.zone.list },
          // { title: 'Groups', path: PATH_DASHBOARD.people.groups.list },
        ],
      },
      {
        title: 'City Control Panel',
        path: PATH_DASHBOARD.city.root,
        icon: ICONS.circle,
        children: [
          { title: 'Cities', path: PATH_DASHBOARD.city.city.list },
          // { title: 'Groups', path: PATH_DASHBOARD.people.groups.list },
        ],
      },
      {
        title: 'Payment Control Panel',
        path: PATH_DASHBOARD.payment.root,
        icon: ICONS.circle,
        children: [
          { title: 'Payment', path: PATH_DASHBOARD.payment.payment.list },
          // { title: 'Groups', path: PATH_DASHBOARD.people.groups.list },
        ],
      },
      {
        title: 'Support',
        path: PATH_DASHBOARD.support.root,
        icon: ICONS.circle,
        children: [
          { title: 'Support', path: PATH_DASHBOARD.support.support.list },
          // { title: 'Groups', path: PATH_DASHBOARD.people.groups.list },
        ],
      },
      {
        title: 'Blogs',
        path: PATH_DASHBOARD.blogs.root,
        icon: ICONS.circle,
        children: [
          { title: 'Blogs', path: PATH_DASHBOARD.blogs.blogs.list },
          // { title: 'Groups', path: PATH_DASHBOARD.people.groups.list },
        ],
      },
      {
        title: 'Frontend Settings',
        path: PATH_DASHBOARD.frontendSettings.root,
        icon: ICONS.circle,
        children: [
          {
            title: 'Hero Media',
            path: PATH_DASHBOARD.frontendSettings.heroMedia.list,
          },
          {
            title: 'How It Works Media',
            path: PATH_DASHBOARD.frontendSettings.howItWorksMedia.list,
          },
          // { title: 'Groups', path: PATH_DASHBOARD.people.groups.list },
        ],
      },
      // {
      //   title: "Support",
      //   path: PATH_DASHBOARD.support.root,
      //   icon: ICONS.circle,
      //   children: [
      //     { title: "Support", path: PATH_DASHBOARD.support.support.list },
      //     // { title: 'Groups', path: PATH_DASHBOARD.people.groups.list },
      //   ],
      // },
      // {
      //   title: 'Offers',
      //   path: PATH_DASHBOARD.offer.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'Sender Offers', path: PATH_DASHBOARD.offer.sender.list },
      //     { title: 'Traveler Offers', path: PATH_DASHBOARD.offer.traveler.list },
      //     { title: 'Accepted Offers', path: PATH_DASHBOARD.offer.accepted.list },
      //     // { title: 'Groups', path: PATH_DASHBOARD.people.groups.list },
      //   ],
      // },

      // {
      //   title: 'Settings',
      //   path: PATH_DASHBOARD.setting.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'Website Settings', path: PATH_DASHBOARD.setting.websetting.form },
      //     // { title: 'Groups', path: PATH_DASHBOARD.people.groups.list },
      //   ],
      // },
      // {
      //   title: 'Hardware',
      //   path: PATH_DASHBOARD.hardware.root,
      //   icon: ICONS.user,
      //   children: [{ title: 'Locks', path: PATH_DASHBOARD.hardware.locks.list }],
      // },
    ],
  },
];

const navConfig = configs;

export default navConfig;
