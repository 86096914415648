import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// Sweet Alert
import Swal from 'sweetalert2';

// utils
import axios from '../../utils/axios';
// import axios from 'axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const headers = { Authorization: `Bearer ${localStorage.getItem('accessToken')}` };

const initialState = {
  isLoading: false,
  createError: null,
  getError: null,
  updateError: null,
  deleteError: null,
  unlocks: [],
  unlockDetails: null,
  groups: [],
  groupDetails: null,
  unlockGroups: [],
  groupUnlocks: [],
};

const slice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS CREATE ERROR
    hasCreateError(state, action) {
      state.isLoading = false;
      state.createError = action.payload;
    },

    // HAS UPDATE ERROR
    hasUpdateError(state, action) {
      state.isLoading = false;
      state.updateError = action.payload;
    },

    // HAS UPDATE ERROR
    hasGetError(state, action) {
      state.isLoading = false;
      state.getError = action.payload;
    },

    // HAS UPDATE ERROR
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload;
    },

    // ERROR SHOWN
    errorShown(state, action) {
      state.createError = null;
      state.updateError = null;
      state.getError = null;
      state.deleteError = null;
    },

    // GET Unlock Success
    getUnlocksSuccess(state, action) {
      state.isLoading = false;
      state.unlocks = action.payload;
    },

    // GET Unlock DETAILS
    getUnlockDetailsSuccess(state, action) {
      state.isLoading = false;
      state.unlockDetails = action.payload;
    },

    // GET Group Success
    getGroupSuccess(state, action) {
      state.isLoading = false;
      state.groups = action.payload;
    },

    // GET Unlock Group Success
    getUnlockGroupSuccess(state, action) {
      state.isLoading = false;
      state.unlockGroups = action.payload;
    },
    // GET Group Unlocks Success
    getGroupUnlockSuccess(state, action) {
      state.isLoading = false;
      state.groupUnlocks = action.payload;
    },

    // GET Group DETAILS
    getGroupDetailsSuccess(state, action) {
      state.isLoading = false;
      state.groupDetails = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Unlock Functions
export function getUnlocks() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('unlocks');
      dispatch(slice.actions.getUnlocksSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function createUnlock(data, handleClose) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('unlocks', data, { headers });
      handleClose(response.data.message);
      Swal.fire(
        'Successfully Created',
        `Your Accocunt (Email: ${response.data.data.email} , Password: ${response.data.data.password}) Please save your Credentials`,
        'success'
      );
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}

export function updateUnlock(data, handleSuccess) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`unlocks?id=${data.id}`, data, { headers });
      console.log(`${response.data} ==========update response`);
      dispatch(slice.actions.stopLoading());
      handleSuccess(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function getUnlockDetails(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`unlocks/${id}`, { headers });
      dispatch(slice.actions.getUnlockDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

// Group Functions

export function getGroups(unlockId = null) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('groups/get-all-groups-with-members');
      dispatch(slice.actions.getGroupSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function getUnlockGroups(unlockId = null) {
  console.log(`${unlockId} in slice`);
  let route = 'groups/get-all-groups-with-members';
  if (unlockId != null) {
    route = 'groups/get-all-groups-belong-to-unlock';
  }
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`groups/get-all-groups-belong-to-unlock?id=${unlockId}`);
      dispatch(slice.actions.getUnlockGroupSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}
export function getGroupUnlocks(groupId = null) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`groups/get-all-unlocks-belong-to-group?id=${groupId}`);
      dispatch(slice.actions.getGroupUnlockSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function createGroup(data, handleClose) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('groups', data, { headers });
      handleClose(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}

export function updateGroup(data, handleSuccess) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`groups?id=${data.id}`, data, { headers });
      dispatch(slice.actions.stopLoading());
      handleSuccess(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function getGroupDetails(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`groups/${id}`, { headers });
      dispatch(slice.actions.getGroupDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function clearError() {
  dispatch(slice.actions.errorShown());
}

// Switch Member in Group
export function switchMembersInGroup(data, handleClose, groupId) {
  const payload = {
    unlockIds: data,
  };
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`groups/add-members-to-group?id=${groupId}`, payload, { headers });
      // handleClose(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}

// Add Member to Group
export function addMembers(data, handleClose, unlockIdd) {
  const payload = {
    unlockId: unlockIdd,
    groupIds: data,
  };
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`groups/add-unlock-to-multiple-groups`, payload, { headers });
      handleClose(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}
