import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// Sweet Alert
import Swal from 'sweetalert2';

// utils
import axios from '../../utils/axios';
// import axios from 'axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

// const headers = { Authorization: `Bearer ${localStorage.getItem('accessToken')}` };
let headers;
// eslint-disable-next-line no-unused-expressions
async () => {
  headers = await {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
};
const initialState = {
  isLoading: false,
  createError: null,
  getError: null,
  updateError: null,
  deleteError: null,
  howItWorkMediaList: [],
  userDetails: null,
  groups: [],
  groupDetails: null,
  userGroups: [],
  groupUsers: [],
};

const slice = createSlice({
  name: 'howItWorkMedia',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS CREATE ERROR
    hasCreateError(state, action) {
      state.isLoading = false;
      state.createError = action.payload;
    },

    // HAS UPDATE ERROR
    hasUpdateError(state, action) {
      state.isLoading = false;
      state.updateError = action.payload;
    },

    // HAS UPDATE ERROR
    hasGetError(state, action) {
      state.isLoading = false;
      state.getError = action.payload;
    },

    // HAS UPDATE ERROR
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload;
    },

    // ERROR SHOWN
    errorShown(state, action) {
      state.createError = null;
      state.updateError = null;
      state.getError = null;
      state.deleteError = null;
    },

    // GET User Success
    getHowItWorkMediaSuccess(state, action) {
      state.isLoading = false;
      state.howItWorkMediaList = action.payload;
    },

    // GET User DETAILS
    getUserDetailsSuccess(state, action) {
      state.isLoading = false;
      state.userDetails = action.payload;
    },

    // GET Group Success
    getGroupSuccess(state, action) {
      state.isLoading = false;
      state.groups = action.payload;
    },

    // GET User Group Success
    getUserGroupSuccess(state, action) {
      state.isLoading = false;
      state.userGroups = action.payload;
    },
    // GET Group Users Success
    getGroupUserSuccess(state, action) {
      state.isLoading = false;
      state.groupUsers = action.payload;
    },

    // GET Group DETAILS
    getGroupDetailsSuccess(state, action) {
      state.isLoading = false;
      state.groupDetails = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// User Functions
export function getHowItWorkMedia() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('auth/all-how-it-work-media');
      dispatch(slice.actions.getHowItWorkMediaSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function createHowItWorkMedia(data, handleClose) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('auth/create-update-how-it-work-media', data, {
        headers,
      });
      handleClose(response.data.message);
      dispatch(getHowItWorkMedia());
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}
export function importHowItWorkMediaCSV(data, handleClose) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('auth/csv/upload', data, { headers });
      handleClose(response.data.message);
      dispatch(getHowItWorkMedia());
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}

export function updateImage(data, handleSuccess) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('auth/update-howItWorkMedia', data, {
        headers,
      });
      dispatch(slice.actions.stopLoading());
      handleSuccess(response.data.message);
      dispatch(getHowItWorkMedia());
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function updateHowItWorkMedia(data, handleSuccess) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('auth/update-howItWorkMedia', data, {
        headers,
      });
      dispatch(slice.actions.stopLoading());
      handleSuccess(response.data.message);
      dispatch(getHowItWorkMedia());
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function updateTag(data, handleSuccess) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('auth/update-howItWorkMedia', data, {
        headers,
      });
      dispatch(slice.actions.stopLoading());
      handleSuccess(response.data.message);
      dispatch(getHowItWorkMedia());
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function getHowItWorkMediaDetails(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`zones/${id}`, { headers });
      dispatch(slice.actions.getHowItWorkMediaDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function deleteHowItWorkMedia(data, handleDeleteRes) {
  return async () => {
    try {
      const response = await axios.post('auth/delete-how-it-work-media', data, {
        headers,
      });
      dispatch(getHowItWorkMedia());
      handleDeleteRes(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasGetError());
      handleDeleteRes(error.message);
    }
  };
}
