import PropTypes from 'prop-types';
import { useState } from 'react';

// Right Side Drawer
import * as React from 'react';
import Drawer from '@mui/material/Drawer';
// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, Checkbox, TableRow, TableCell, Typography, MenuItem, Box, Button } from '@mui/material';
// components
import Fab from '@mui/material/Fab';
import UserDrawer from '../UserDrawer';
import TravellerPaymentStatus from './TravellerPaymentStatus';
import TravellerRefundStatus from './TravellerRefundStatus';
import SenderRefundStatus from './SenderRefundStatus';
import OrderStatus from './OrderStatus';
import DeliveryStatus from './DeliveryStatus';
import SenderPaymentStatus from './SenderPaymentStatus';
// ----------------------------------------------------------------------

OrderTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function OrderTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [userDetail, setUserDetail] = React.useState({});
  const [isDetail, setDetail] = React.useState(false);

  const toggleDrawer = (anchor, open, row) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });

    setUserDetail(row);
  };
  const theme = useTheme();

  const {
    id,
    d_country,
    d_city,
    d_address,
    s_country,
    s_city,
    offer_price,
    s_address,
    package_weight,
    package_height,
    additional_notes,
    rating,
    package_length,
    package_width,
    payment_status,
    package_type,
    user_id,
    user,
    acceptedTravelerOffer,
    max_weight,
    acceptedSenderOffer,
    package_value,
    createdAt,
    offer_amount,
    status,
  } = row;
  console.log('rowwwwwwwwwwwwwwwwwwwwwwwww', row);

  const [rowData, setRowData] = useState(null);

  const [orderStatus, setOrderStatus] = useState(false);
  const [deliveryStatus, setDeliveryStatus] = useState(false);
  const [senderPaymentStatus, setSenderPaymentStatus] = useState(false);
  const [travellerPaymentStatus, setTravellerPaymentStatus] = useState(false);
  const [senderRefundStatus, setSenderRefundStatus] = useState(false);
  const [travellerRefundStatus, setTravellerRefundStatus] = useState(false);

  const handleTravellerPaymentStatus = () => {
    setTravellerPaymentStatus(!travellerPaymentStatus);
  };
  // const handleSelect = () => {
  //   let servicesData =  {
  //     adminId:id
  //   }
  //   // dispatch(getEnabledServices(servicesData, handleTravellerPaymentStatus));
  //   setTravellerPaymentStatus(!travellerPaymentStatus);
  // };
  const handleSenderRefundStatus = () => {
    setRowData(row);
    setSenderRefundStatus(!senderRefundStatus);
  };
  const handleTravellerRefundStatus = () => {
    setRowData(row);
    setTravellerRefundStatus(!travellerRefundStatus);
  };
  const handleSenderPaymentStatus = () => {
    setRowData(row);
    setSenderPaymentStatus(!senderPaymentStatus);
  };
  const handleOrderStatus = () => {
    setRowData(row);
    setOrderStatus(!orderStatus);
  };
  const handleDeliveryStatus = () => {
    setRowData(row);
    setDeliveryStatus(!deliveryStatus);
  };

  return (
    <>
      <TableRow hover selected={selected} style={{ cursor: 'pointer' }}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        {/* <TableCell align="left">id</TableCell> */}
        <TableCell align="left">TO-{id}</TableCell>
        <TableCell align="left">
          <Box sx={{ width: 100 }}>{createdAt.split('T')[0]}</Box>
        </TableCell>

        {/* <TableCell align="left">Sender</TableCell> */}
        <TableCell align="left">
          <Button
            variant="contained"
            // component={RouterLink}
            to=""
            onClick={handleOrderStatus}
            //   onClick={handleResetPassword}
            //   startIcon={<Iconify icon={'eva:plus-fill'} />}
          >
            {status}
          </Button>
        </TableCell>
        {/* <TableCell align="left">
          <Button
            variant="contained"
            // component={RouterLink}
            to=""
            onClick={handleDeliveryStatus}
            //   onClick={handleResetPassword}
            //   startIcon={<Iconify icon={'eva:plus-fill'} />}
          >
            Progress
          </Button>
        </TableCell> */}

        <TableCell align="left">
          {user?.firstName} {user?.lastName}
        </TableCell>
        <TableCell align="left">
          {/* {acceptedTravelerOffer?.accepted_by_sender ? acceptedTravelerOffer?.accepted_by_sender : 'not accepted'} */}
          {acceptedTravelerOffer?.user
            ? `${acceptedTravelerOffer?.user?.firstName} ${acceptedTravelerOffer?.user?.lastName}`
            : 'Not Accepted'}
        </TableCell>

        <TableCell align="left">{package_type}</TableCell>
        <TableCell align="left">{s_country}</TableCell>
        <TableCell align="left">{s_city}</TableCell>
        <TableCell align="left">{s_address}</TableCell>
        <TableCell align="left">{d_country}</TableCell>
        <TableCell align="left">{d_city}</TableCell>
        <TableCell align="left">{d_address}</TableCell>
        <TableCell align="left">{max_weight}</TableCell>
        <TableCell align="left">
          {package_length}*{package_height}* {package_width}
        </TableCell>
        {/* <TableCell align="left">{package_value}</TableCell> */}
        <TableCell align="left">{package_type}</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell>
        {/* <TableCell align="left">to be implemented in next phase</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell> */}
        {/* <TableCell align="left">to be implemented in next phase</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell> */}
        <TableCell align="left">{offer_price}</TableCell>
        <TableCell align="left">Stripe</TableCell>
        <TableCell align="left">{payment_status}</TableCell>
        {/* <TableCell align="left">to be implemented in next phase</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell>*/}
        <TableCell align="left">to be implemented in next phase</TableCell>
        {/* <TableCell align="left">to be implemented in next phase</TableCell> */}
        {/* <TableCell align="left">{additional_notes}</TableCell> */}
        {/* <TableCell align="left">to be implemented in next phase</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell>
        <TableCell align="left">to be implemented in next phase</TableCell> */}
        <TableCell align="left">{rating?.stars ? rating?.stars + ' stars' : 'no rating'}</TableCell>
        {/* <TableCell align="left">to be implemented in next phase</TableCell> */}

        <TravellerPaymentStatus
          open={travellerPaymentStatus}
          row={row}
          handleClose={handleTravellerPaymentStatus}
          rowData={rowData}
        />
        <SenderPaymentStatus open={senderPaymentStatus} handleClose={handleSenderPaymentStatus} rowData={rowData} />
        <OrderStatus open={orderStatus} handleClose={handleOrderStatus} rowData={rowData} />
        <DeliveryStatus open={deliveryStatus} handleClose={handleDeliveryStatus} rowData={rowData} />
        <SenderRefundStatus open={senderRefundStatus} handleClose={handleSenderRefundStatus} rowData={rowData} />
        <TravellerRefundStatus
          open={travellerRefundStatus}
          handleClose={handleTravellerRefundStatus}
          rowData={rowData}
        />

        {/* <TableCell align="center">
        <Iconify
          icon={isVerified ? 'eva:checkmark-circle-fill' : 'eva:clock-outline'}
          sx={{
            width: 20,
            height: 20,
            color: 'success.main',
            ...(!isVerified && { color: 'warning.main' }),
          }}
        />
      </TableCell> */}

        {/* <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(status === 'banned' && 'error') || 'success'}
          sx={{ textTransform: 'capitalize' }}
        >
          {status}
        </Label>
      </TableCell> */}
      </TableRow>

      {/* <Drawer
        style={{ width: '324px', overflowX: 'hidden' }}
        anchor="right"
        open={state.right}
        onClose={toggleDrawer('right', false)}
      >
        {list("right")}
        <UserDrawer position="right" userDetail={userDetail} />
      </Drawer> */}
    </>
  );
}
