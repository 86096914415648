// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page403: "/403",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    ecommerce: path(ROOTS_DASHBOARD, "/ecommerce"),
    analytics: path(ROOTS_DASHBOARD, "/analytics"),
    banking: path(ROOTS_DASHBOARD, "/banking"),
    booking: path(ROOTS_DASHBOARD, "/booking"),
  },
  roles: {
    new: path(ROOTS_DASHBOARD, "/roles/new"),
    list: path(ROOTS_DASHBOARD, "/roles/list"),
    details: path(ROOTS_DASHBOARD, "/roles/details"),
  },
  rolePermissions: {
    new: path(ROOTS_DASHBOARD, "/roles/new"),
    list: path(ROOTS_DASHBOARD, "/rolePermissions/list"),
    details: path(ROOTS_DASHBOARD, "/roles/details"),
  },
  logs: {
    root: path(ROOTS_DASHBOARD, "/logs"),
    presence: {
      list: path(ROOTS_DASHBOARD, "/logs/presence/list"),
    },
    unlocks: {
      list: path(ROOTS_DASHBOARD, "/logs/unlocks/list"),
    },
  },

  locations: {
    root: path(ROOTS_DASHBOARD, "/locations"),
    sites: {
      list: path(ROOTS_DASHBOARD, "/locations/sites/list"),
    },
    zones: {
      list: path(ROOTS_DASHBOARD, "/locations/zones/list"),
    },
    areas: {
      list: path(ROOTS_DASHBOARD, "/locations/areas/list"),
    },
  },

  people: {
    root: path(ROOTS_DASHBOARD, "/people"),
    users: {
      list: path(ROOTS_DASHBOARD, "/people/users/list"),
    },
    // groups: {
    //   list: path(ROOTS_DASHBOARD, '/people/groups/list'),
    // },
  },

  countries: {
    root: path(ROOTS_DASHBOARD, "/countries"),
    countries: {
      list: path(ROOTS_DASHBOARD, "/countries/countries/list"),
    },
  },
  zone: {
    root: path(ROOTS_DASHBOARD, "/zone"),
    zone: {
      list: path(ROOTS_DASHBOARD, "/zone/zone/list"),
    },
  },
  city: {
    root: path(ROOTS_DASHBOARD, "/city"),
    city: {
      list: path(ROOTS_DASHBOARD, "/city/city/list"),
    },
  },
  payment: {
    root: path(ROOTS_DASHBOARD, "/payment"),
    payment: {
      list: path(ROOTS_DASHBOARD, "/payment/payment/list"),
    },
  },
  support: {
    root: path(ROOTS_DASHBOARD, "/support"),
    support: {
      list: path(ROOTS_DASHBOARD, "/support/support/list"),
    },
  },
  blogs: {
    root: path(ROOTS_DASHBOARD, "/blogs"),
    blogs: {
      list: path(ROOTS_DASHBOARD, "/blogs/blogs/list"),
    },
  },
  frontendSettings: {
    root: path(ROOTS_DASHBOARD, "/frontendSettings"),
    heroMedia: {
      list: path(ROOTS_DASHBOARD, "/frontendSettings/heroMedia/list"),
    },
    howItWorksMedia: {
      list: path(ROOTS_DASHBOARD, "/frontendSettings/howItWorksMedia/list"),
    },
  },
  orders: {
    root: path(ROOTS_DASHBOARD, "/orders"),
    orders: {
      list: path(ROOTS_DASHBOARD, "/orders/orders/list"),
    },
    traveller: {
      list: path(ROOTS_DASHBOARD, "/orders/traveller/list"),
    },
  },
  offer: {
    root: path(ROOTS_DASHBOARD, "/offer"),
    sender: {
      list: path(ROOTS_DASHBOARD, "/offer/sender/list"),
    },
    traveler: {
      list: path(ROOTS_DASHBOARD, "/offer/traveler/list"),
    },
    accepted: {
      list: path(ROOTS_DASHBOARD, "/offer/traveler/list"),
    },
  },

  setting: {
    root: path(ROOTS_DASHBOARD, "/setting"),
    websetting: {
      form: path(ROOTS_DASHBOARD, "/setting/websetting/form"),
    },
  },

  hardware: {
    root: path(ROOTS_DASHBOARD, "/hardware"),
    locks: {
      list: path(ROOTS_DASHBOARD, "/hardware/locks/list"),
    },
  },

  // presence: {
  //   new: path(ROOTS_DASHBOARD, '/areas/new'),
  //   list: path(ROOTS_DASHBOARD, '/presence/list'),
  //   details: path(ROOTS_DASHBOARD, '/areas/details'),
  // },
  locks: {
    new: path(ROOTS_DASHBOARD, "/areas/new"),
    list: path(ROOTS_DASHBOARD, "/locks/list"),
    details: path(ROOTS_DASHBOARD, "/areas/details"),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, "/mail"),
    all: path(ROOTS_DASHBOARD, "/mail/all"),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, "/chat"),
    new: path(ROOTS_DASHBOARD, "/chat/new"),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, "/calendar"),
  kanban: path(ROOTS_DASHBOARD, "/kanban"),
  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    new: path(ROOTS_DASHBOARD, "/user/new"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  // group: {
  //   root: path(ROOTS_DASHBOARD, '/group'),
  //   new: path(ROOTS_DASHBOARD, '/group/new'),
  //   list: path(ROOTS_DASHBOARD, '/group/list'),
  //   cards: path(ROOTS_DASHBOARD, '/group/cards'),
  //   profile: path(ROOTS_DASHBOARD, '/group/profile'),
  //   account: path(ROOTS_DASHBOARD, '/group/account'),
  //   edit: (name) => path(ROOTS_DASHBOARD, `/group/${name}/edit`),
  //   demoEdit: path(ROOTS_DASHBOARD, `/group/reece-chung/edit`),
  // },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, "/e-commerce"),
    shop: path(ROOTS_DASHBOARD, "/e-commerce/shop"),
    list: path(ROOTS_DASHBOARD, "/e-commerce/list"),
    checkout: path(ROOTS_DASHBOARD, "/e-commerce/checkout"),
    new: path(ROOTS_DASHBOARD, "/e-commerce/product/new"),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-blazer-low-77-vintage/edit"
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-air-force-1-ndestrukt"
    ),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, "/invoice"),
    list: path(ROOTS_DASHBOARD, "/invoice/list"),
    new: path(ROOTS_DASHBOARD, "/invoice/new"),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit"
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5"
    ),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, "/blog"),
    posts: path(ROOTS_DASHBOARD, "/blog/posts"),
    new: path(ROOTS_DASHBOARD, "/blog/new"),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(
      ROOTS_DASHBOARD,
      "/blog/post/apply-these-7-secret-techniques-to-improve-event"
    ),
  },
};
