import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
// import axios from 'axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const headers = { Authorization: `Bearer ${localStorage.getItem('accessToken')}` };

const initialState = {
  isLoading: false,
  createError: null,
  getError: null,
  updateError: null,
  deleteError: null,
  roles: [],
  roleDetails: null,
  permissions: [],
  rolesWithPermissions: [],
  groups: [],
  groupDetails: null,
};

const slice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS CREATE ERROR
    hasCreateError(state, action) {
      state.isLoading = false;
      state.createError = action.payload;
    },

    // HAS UPDATE ERROR
    hasUpdateError(state, action) {
      state.isLoading = false;
      state.updateError = action.payload;
    },

    // HAS UPDATE ERROR
    hasGetError(state, action) {
      state.isLoading = false;
      state.getError = action.payload;
    },

    // HAS UPDATE ERROR
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload;
    },

    // ERROR SHOWN
    errorShown(state, action) {
      state.createError = null;
      state.updateError = null;
      state.getError = null;
      state.deleteError = null;
    },

    // GET Role Success
    getRolesSuccess(state, action) {
      state.isLoading = false;
      state.roles = action.payload;
    },

    // GET Role DETAILS
    getRoleDetailsSuccess(state, action) {
      state.isLoading = false;
      state.roleDetails = action.payload;
    },

    // GET Permission Success
    getPermissionsSuccess(state, action) {
      state.isLoading = false;
      state.permissions = action.payload;
    },

    // GET Roles with Permission Success
    getRolesWithPermissionsSuccess(state, action) {
      state.isLoading = false;
      state.rolesWithPermissions = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Role Functions
// ----------------------------------------------------------------------

export function getRoles() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('roles');
      dispatch(slice.actions.getRolesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function createRole(data, handleClose) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('roles', data, { headers });
      handleClose(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}

export function updateRole(data, handleSuccess) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`roles?id=${data.id}`, data, { headers });
      dispatch(slice.actions.stopLoading());
      handleSuccess(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function getRoleDetails(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`roles/${id}`, { headers });
      dispatch(slice.actions.getRoleDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function deleteRole(id, handleDeleteRes) {
  return async () => {
    try {
      const response = await axios.delete(`roles?id=${id}`, { headers });

      handleDeleteRes(response.data.message);
      dispatch(getRoles());
    } catch (error) {
      dispatch(slice.actions.hasGetError());
      handleDeleteRes(error.message);
    }
  };
}

// Rermission Functions
// ----------------------------------------------------------------------

export function getPermission() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('permissions');
      dispatch(slice.actions.getPermissionsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function getRolesWithPermission() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('permissions/get-roles-with-permissions');
      dispatch(slice.actions.getRolesWithPermissionsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function addRemovePermissions(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('permissions/add-remove-roles-pemissions', { data });
      dispatch(slice.actions.getRolesWithPermissionsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

// Group Functions

export function getGroups() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('groups/get-all-groups-details');
      dispatch(slice.actions.getRolesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function createGroup(data, handleClose) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('group', data, { headers });
      handleClose(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasCreateError(error));
    }
  };
}

export function updateGroup(data, handleSuccess) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`group?id=${data.id}`, data, { headers });
      dispatch(slice.actions.stopLoading());
      handleSuccess(response.data.message);
    } catch (error) {
      dispatch(slice.actions.hasUpdateError(error));
    }
  };
}

export function getGroupDetails(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`group/${id}`, { headers });
      dispatch(slice.actions.getGroupDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}

export function clearError() {
  dispatch(slice.actions.errorShown());
}
