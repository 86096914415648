/* eslint-disable import/no-unresolved */
import { paramCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
// @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from '@mui/material';
import Swal from 'sweetalert2';
import { OrderTableRow } from 'src/sections/@dashboard/travellerorder/list';
import { deleteTravellerOffer } from 'src/redux/slices/offers';
import { useDispatch, useSelector } from '../../redux/store';
import { getOrders } from '../../redux/slices/order';
import { getRoles } from '../../redux/slices/roles';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
// _mock_
import { _userList } from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../components/table';
// sections
import { UserTableToolbar, UserTableRow } from '../../sections/@dashboard/user/list';
import CreateUserModal from '../../sections/@dashboard/user/CreateUserModal';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = ['all', 'active', 'banned'];

const ROLE_OPTIONS = [
  'all',
  'ux designer',
  'full stack designer',
  'backend developer',
  'project manager',
  'leader',
  'ui designer',
  'ui/ux designer',
  'front end developer',
  'full stack developer',
];

// ----------------------------------------------------------------------
const toastOptions = {
  position: 'top-right',
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
  theme: 'dark',
};

export default function TravellerOrder() {
  const { t } = useTranslation();
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const TABLE_HEAD = [
    { id: 'id', label: t('Select'), align: 'left' },
    { id: 'id', label: t('Order Id'), align: 'left' },
    // { id: 'first_name', label: t('Initiated By'), align: 'left' },
    { id: 'order_cancellations', label: t('Date Of Offer Created'), align: 'left' },
    { id: 'last_name', label: t('Status'), align: 'left' },
    // { id: 'country', label: t('Traveller Id'), align: 'left' },
    { id: 'country', label: t('Traveller User Id'), align: 'left' },
    // { id: 'address', label: t('Sender User Id'), align: 'left' },
    { id: 'address', label: t('Sender User Id'), align: 'left' },
    { id: 'birth_date', label: t('Parcel Type'), align: 'left' },
    { id: 'gender', label: t('From Country'), align: 'left' },
    { id: 'status', label: t('From City'), align: 'left' },
    { id: 'date', label: t('From Address'), align: 'left' },
    { id: 'login', label: t('To Country'), align: 'left' },
    { id: 'balance', label: t('To City'), align: 'left' },
    { id: 'promo_code', label: t('To Address'), align: 'left' },
    { id: 'promo_status', label: t('Item Weight'), align: 'left' },
    { id: 'points_earned', label: t('Item Dimensions'), align: 'left' },
    // { id: 'points_radeem', label: t('ItemValue'), align: 'left' },
    { id: 'points_balance', label: t('Item Category'), align: 'left' },
    // { id: 'sender_orders', label: t('ItemDescription'), align: 'left' },
    { id: 'refunds', label: t('Date Of Offer Accepted'), align: 'left' },
    { id: 'ratings', label: t('Date Of Offer Expired'), align: 'left' },
    { id: 'average_rating', label: t('Date Picked Up From Buyer'), align: 'left' },
    { id: 'reviews', label: t('Date Delivered'), align: 'left' },
    // { id: 'average_review', label: t('Delivery Fee'), align: 'left' },
    // { id: 'average_review', label: t('Insurance Fee'), align: 'left' },
    // { id: 'average_review', label: t('First Mile Fee'), align: 'left' },
    // { id: 'average_review', label: t('Last Mile Fee'), align: 'left' },
    // { id: 'average_review', label: t('Other Fee'), align: 'left' },
    { id: 'average_review', label: t('Total Cost'), align: 'left' },
    { id: 'average_review', label: t('Sender Payment Method'), align: 'left' },
    { id: 'average_review', label: t('Sender Payment Status'), align: 'left' },
    { id: 'average_review', label: t('Sender Payment Date'), align: 'left' },
    // { id: 'average_review', label: t('Traveller Payment Method'), align: 'left' },
    // { id: 'average_review', label: t('Traveller Payment Status'), align: 'left' },
    // { id: 'average_review', label: t('Traveller Payment Method'), align: 'left' },
    // { id: 'average_review', label: t('Item Insurance'), align: 'left' },
    // { id: 'average_review', label: t('Sender Notes On Offer'), align: 'left' },
    // { id: 'average_review', label: t('Traveller Notes On Acceptance'), align: 'left' },
    // { id: 'average_review', label: t('Delivery Instructions'), align: 'left' },
    // { id: 'average_review', label: t('Pickup Photos'), align: 'left' },
    // { id: 'average_review', label: t('Sender Cancellation Date'), align: 'left' },
    // { id: 'average_review', label: t('Traveller Cancellation Date'), align: 'left' },
    // { id: 'average_review', label: t('Sender Cancellation Reason'), align: 'left' },
    // { id: 'average_review', label: t('Traveller Cancellation Reason'), align: 'left' },
    // { id: 'average_review', label: t('Sender Refund Amount'), align: 'left' },
    // { id: 'average_review', label: t('Traveller Refund Amount'), align: 'left' },
    // { id: 'average_review', label: t('Sender Refund Status'), align: 'left' },
    // { id: 'average_review', label: t('Traveller Refund Status'), align: 'left' },
    // { id: 'average_review', label: t('Rating Of Seller'), align: 'left' },
    { id: 'average_review', label: t('Rating Of Traveller'), align: 'left' },
  ];

  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [rolesData, setRoleData] = useState([]);

  const [filterName, setFilterName] = useState('');
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const [filterRole, setFilterRole] = useState('all');

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };
  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };

  // const handleDeleteRow = (id) => {
  //   const deleteRow = tableData.filter((row) => row.id !== id);
  //   setSelected([]);
  //   setTableData(deleteRow);
  // };

  // const handleDeleteRows = (selected) => {
  //   const deleteRows = tableData.filter((row) => !selected.includes(row.id));
  //   setSelected([]);
  //   setTableData(deleteRows);
  // };

  const handleEditRow = (id) => {
    navigate(PATH_DASHBOARD.user.edit(paramCase(id)));
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });
  console.log('tableDataaaaa', dataFiltered);

  const denseHeight = dense ? 52 : 72;

  const isNotFound = !dataFiltered.length && !!filterName;
  const { orders } = useSelector((state) => state.order);
  const { roles } = useSelector((state) => state.roles);
  // console.log(`${roles}API Data`)
  useEffect(() => {
    dispatch(getOrders());
  }, [dispatch]);

  useEffect(() => {
    console.log('orderss', orders);
    if (orders?.travelerOffers?.length > 0) {
      setTableData(orders?.travelerOffers);
    }
  }, [orders?.travelerOffers]);
  const handleOpenCreateNewSiteModal = (successMessage) => {
    if (successMessage) {
      toast.success(successMessage, toastOptions);
      dispatch(getOrders());
    }
    setOpenCreateModal(!openCreateModal);
  };
  console.log('orderssasdasdsad', tableData);
  const handleDeleteRes = (message) => {
    Swal.fire('Successfully Deleted', message, 'info');
  };
  const handleDeleteRow = (id) => {
    console.log('iddddddddddddddd', id);
    const TravellerOfferData = {
      travelerOfferId: id,
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this Traveller Offer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'red',
      cancelButtonColor: 'grey',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteTravellerOffer(TravellerOfferData, handleDeleteRes));
      }
    });
    setSelected([]);
  };
  return (
    <Page title={t('TravellerOrder')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={t('Traveller Order List')}
          links={[
            { name: t('dashboard'), href: PATH_DASHBOARD.root },
            // { name: 'User', href: PATH_DASHBOARD.user.root },
            { name: t('TravellerOrder') },
          ]}
          // action={
          //   <Button
          //     variant="contained"
          //     component={RouterLink}
          //     to=""
          //     onClick={handleOpenCreateNewSiteModal}
          //     startIcon={<Iconify icon={'eva:plus-fill'} />}
          //   >
          //     {t('newUser')}
          //   </Button>
          // }
        />

        <Card>
          <UserTableToolbar filterName={filterName} onFilterName={handleFilterName} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRow(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                />

                <TableBody>
                  {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <OrderTableRow
                      key={row.id}
                      row={row}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                    />
                  ))}

                  <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
      {/* <CreateUserModal roles={rolesData} open={openCreateModal} handleClose={handleOpenCreateNewSiteModal} /> */}
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName, filterStatus, filterRole }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);
  console.log('11111111111111', stabilizedThis);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);
  console.log('22222222222', tableData);

  // if (filterName) {
  //   tableData = tableData.filter((item) => item.firstName.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  //   console.log('33333333', tableData);
  // }

  // if (filterStatus !== 'all') {
  //   tableData = tableData.filter((item) => item.status === filterStatus);
  //   console.log('4444444444', tableData);
  // }

  // if (filterRole !== 'all') {
  //   tableData = tableData.filter((item) => item.role === filterRole);
  //   console.log('5555555', tableData);
  // }
  // console.log('666666666666', tableData);

  return tableData;
}
