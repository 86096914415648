import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import {
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Autocomplete,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Close } from "@mui/icons-material";
// import { importTagCSV } from "../../../redux/slices/city";
import { useDispatch, useSelector } from "../../../redux/store";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import { MuiChipsInput } from "mui-chips-input";
import { updateImage, updateTag } from "src/redux/slices/blog";
const toastOptions = {
  position: "top-right",
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
  theme: "dark",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        borderBottom: "1px solid #e5e8ec",
        width: 600,
        backgroundColor: "#f4f6f8",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function TagsModal({ rowData, open, handleClose }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [chips, setChips] = React.useState([]);
  const { isLoading } = useSelector((state) => state.blog);
  console.log(
    rowData?.blogTags?.map((val) => {
      return val.tag;
    })
  );
  useEffect(() => {
    setChips(
      rowData?.blogTags?.map((val) => {
        return val.tag;
      })
    );
    console.log("tags", chips);
  }, [rowData]);

  const handleSubmit = () => {
    dispatch(updateTag(chips));
  };

  const handleChange = (newChips) => {
    setChips(newChips);
  };

  return (
    <div>
      <ToastContainer />

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {t("Add/Remove Tag")}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <FormControl style={{ margin: 15 }}>
              <MuiChipsInput value={chips} onChange={handleChange} />
            </FormControl>
            <Stack
              sx={{
                mt: 3,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <LoadingButton
                onClick={() => handleClose()}
                variant="outlined"
                style={{ borderRadius: "25px", marginRight: "15px" }}
              >
                {t("cancel")}
              </LoadingButton>

              <LoadingButton
                style={{ borderRadius: "25px" }}
                // disabled={!data?.zoneName}
                onClick={handleSubmit}
                variant="contained"
                loading={isLoading}
              >
                {t("Submit")}
              </LoadingButton>
            </Stack>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
