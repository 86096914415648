import * as React from 'react';
import { useCallback } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';

import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { LoadingButton, Autocomplete } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/CreateUserModal.css';
// Redux
import { createUser, clearError, ResetPassword } from '../../../redux/slices/people';
import { useDispatch, useSelector } from '../../../redux/store';

const toastOptions = {
  position: 'top-right',
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
  theme: 'dark',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #FFFFFF',
  boxShadow: 24,
  borderRadius: '20px',
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, borderBottom: '1px solid #e5e8ec', width: 600, backgroundColor: '#f4f6f8' }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/prop-types
export default function ResetPasswordModal({ id, open, handleClose }) {
  const { t } = useTranslation();

  // console.log(`${roles}===============roles`)
  const dispatch = useDispatch();
  const [data, setData] = React.useState();
  const [roleId, setRoleId] = React.useState();

  const { createError, isLoading } = useSelector((state) => state.people);

  React.useEffect(() => {
    if (createError) {
      toast.error(createError.message, toastOptions);
      dispatch(clearError);
    }
  }, [createError]);

  const handleSubmit = () => {
    const userData = { ...data };
    userData.userId = id;
    dispatch(ResetPassword(userData, handleClose));
  };

  const handleChangeUserData = (event) => {
    const userData = { ...data };
    userData[event.target.name] = event.target.value;
    setData(userData);
  };

  return (
    <div>
      <ToastContainer />
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t('Reset Password')}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid
            style={{
              padding: '30px',
            }}
            container
            spacing={1}
          >
            <Grid item lg={6} md={6}>
              <TextField name="password" id="outlined-basic" label={t('Enter Password')} variant="outlined" required />
            </Grid>
            <Grid item lg={6} md={6}>
              <TextField
                name="newPassword"
                onChange={handleChangeUserData}
                id="outlined-basic"
                label={t('Confirm Password')}
                variant="outlined"
                required
              />
            </Grid>
            <Stack sx={{ mt: 3, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>
              <LoadingButton
                onClick={() => handleClose()}
                variant="outlined"
                style={{ borderRadius: '25px', marginRight: '15px' }}
              >
                {t('cancel')}
              </LoadingButton>

              <LoadingButton
                style={{ borderRadius: '25px' }}
                disabled={!data?.newPassword}
                onClick={handleSubmit}
                variant="contained"
                loading={isLoading}
              >
                {t('Reset Passord')}
              </LoadingButton>
            </Stack>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
